import { MainItem, Field, Email, Tel, Alert } from '../../common/services/main/main.model';
// import { FileRecord } from '../../common/services/upload-file/upload-file-model';
import { SettingService } from '../../common/services/setting/setting.service';


export class Contact extends MainItem {

    values: {
        $key: string,
        ref: number,
        created: firebase.firestore.FieldValue,
        createdBy: string,
        updated: firebase.firestore.FieldValue,
        updatedBy: string,
        alerts: Alert[],
        synaps: any,

        title: string,
        firstname: string,
        lastname: string,
        nickname: string,
        fullname: string,
        emails: { [id: string]: Email },
        tels: { [id: string]: Tel },
        country: string, // Living
        fullAddress: string, //IF not Mauritius
        nationalities: string[],
        permit: string,
        birthday: Date,
        lang: string,
        soncas: string[],
        enews: string[],
        // photoFace: FileRecord,

        // blackListed: boolean,
        // HNWI: boolean,
        // UHNWI: boolean,
    }

    constructor(
        values: any = null,
        public setting?: SettingService,
    ) {
        super(
            'contacts',
            {
                title: new Field({ label: 'Title', input: 'select', inputOptions: { param: 'titles' } }),
                firstname: new Field({ label: 'Firstname', checks: { trim: true } }),
                // otherNames: new Field({ label: 'Other Names', }),
                lastname: new Field({ label: 'Lastname', checks: { trim: true } }),
                nickname: new Field({ label: 'Nickname', checks: { trim: true } }),
                fullname: new Field({
                    input: 'auto',
                    autoFn: {
                        toFB: (values, setting) => { return this._createFullName(setting, values); }
                    },

                }),
                emails: new Field({
                    label: 'Emails',
                    input: 'dynSub',
                    dynSub: {
                        email: new Field({ label: 'Email', checks: { space: true } }),
                        enews: new Field({ label: 'Enews', input: 'boolean' }),
                    },
                }),
                tels: new Field({
                    label: 'Tels',
                    input: 'dynSub',
                    dynSub: {
                        number: new Field({ label: 'Number', checks: { space: true } }),
                    },
                }),

                country: new Field({ label: 'Country (Living)', input: 'select', default: 'MU', inputOptions: { param: 'countries' } }),
                fullAddress: new Field({
                    label: 'Address (Abroad)',
                    show: (data: any) => data && !(data.country && data.country === 'MU'),
                }),

                nationalities: new Field({
                    label: 'Nationalies', input: 'select', inputOptions: { param: 'countries', multi: true },
                }),
                birthday: new Field({ label: 'Birthday', input: 'date' }),
                lang: new Field({
                    label: 'Language', input: 'select', inputOptions: { param: 'langs' },
                    default: 'en', validations: { required: true }
                }),
                soncas: new Field({
                    label: 'SONCAS', input: 'select', inputOptions: { param: 'soncas', multi: true },
                }),
                // photoFace: Fil;
                // blackListed: new Field({ input: 'boolean', label: 'Black Listed', }),
                // HNWI: new Field({ input: 'boolean', label: 'HNWI > 1M$', }),
                // UHNWI: new Field({ input: 'boolean', label: 'UHNWI > 30M$', }),
             

            },
            // Forms
            {
                main: {
                    title: null,
                    fields: ['title', 'firstname', 'lastname', 'nickname', 'nationalities', 'country', 'fullAddress', 'birthday', 'lang']
                },
                emails: {
                    title: null,
                    fields: ['emails']
                },
                tels: {
                    title: null,
                    fields: ['tels']
                },
                short: {
                    title: null,
                    fields: ['title', 'firstname', 'lastname', 'nickname', 'nationalities', 'lang']
                },
            },
            // Tables
            {
                main: {
                    title: 'Contact List',
                    columns: [
                        { field: 'icon', type: 'icon', label: '' },
                        { field: 'ref', type: 'string', label: 'Ref', options: { prefix: 'I' } },
                        { field: 'firstname', type: 'string', label: 'Firstname' },
                        { field: 'lastname', type: 'string', label: 'Lastname' },
                        { field: 'units', type: 'number', label: 'Properties' },
                        { field: 'projects', type: 'number', label: 'Projects' },
                    ],
                },
                employees: {
                    title: 'Employee List',
                    columns: [
                        { field: 'icon', type: 'icon', label: '' },
                        { field: 'ref', type: 'string', label: 'Ref', options: { prefix: 'I' } },
                        { field: 'fullname', type: 'string', label: 'Name' },
                    ],
                },
            },
            // Config File
            {
                path: 'contacts/', filename: (data) => {
                    let filename = 'contact-';
                    filename += 'I' + data.ref;
                    return filename
                }
            },
            setting,
        );
        if (values) {
            this.fromFB(values);
        }

    }


    private _createFullName(setting: SettingService, values): string {

        let fullname = '';
        if (values.title) {
            fullname += setting.getParamValueName('titles', values.title) + ' ';
        }
        if (values.firstname) {
            fullname += values.firstname;
        }
        if (values.firstname && values.lastname) {
            fullname += ' ';
        }
        if (values.lastname) {
            fullname += values.lastname;
        }
        if (values.nickname) {
            fullname += ' (' + values.nickname + ')';
        }
        return fullname;
    }


}