import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../auth/auth.service';
import { MainService } from '../main/main.service';
import { FileCRM, FileRecord, Upload } from './file.model';
import { HttpClient } from '@angular/common/http';
import { MainItem } from 'src/common/services/main/main.model';

import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class GoinfreService extends MainService {

  public refMode = true;



  constructor(
    public auth: AuthService,
    public afs: AngularFirestore,
    public http: HttpClient,
  ) {
    super(auth, afs, 'file', 'files', FileCRM);

  }


  pushUpload(upload: Upload, type: string, item: MainItem): Promise<any> {
    return new Promise((resolve, reject) => {  // <-- Added reject
      if (type && !this._typeCheck(upload, type)) {
        return resolve(null);
      }

      const re = /(?:\.([^.]+))?$/;
      const fileExtension = re.exec(upload.file.name)[1]?.toLowerCase(); // <-- Added safe access

      let storageRef = firebase.storage().ref();
      const filename = `${upload.name}-${this._random()}.${fileExtension}`;
      let uploadTask = storageRef.child(`${upload.path}${filename}`).put(upload.file);

      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
          // Upload in progress
          const snap = snapshot as firebase.storage.UploadTaskSnapshot;
          upload.progress = Math.round((snap.bytesTransferred / snap.totalBytes) * 100);
          upload.setProgress(upload.progress);
        },
        (error) => {
          // Upload failed
          console.log(error);
          reject(error);  // <-- Reject the promise on error
        },
        async () => {
          // Upload success
          try {
            const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
            const info = {
              url: downloadURL,
              name: filename,
              path: upload.path,
              type: upload.file.type,
            }
            const newFile = new FileCRM(info);

            if (item.module === 'units') {
              newFile.values.synaps = { filesUnits: {} };
              newFile.values.synaps.filesUnits[item.values.$key] = true;
            } else if (item.module === 'residences') {
              newFile.values.synaps = { filesResidences: {} };
              newFile.values.synaps.filesResidences[item.values.$key] = true;
            }

            await this.saveNewItem(newFile);
            resolve(newFile);  // <-- Ensure Promise resolves correctly
          } catch (err) {
            reject(err);  // <-- Handle errors properly
          }
        }
      );
    });
  }

  protected _random(): string {
    return Math.random().toString(36).substr(2, 9);
  }

  deleteFileUpload(file: FileRecord, item: MainItem): Promise<any> {
    //TODO: do not remove if linked to something else

    if (file.$key) {
      this.deleteFileKey(file.$key);
    }

    const storageRef = firebase.storage().ref();
    return storageRef.child(`${file.path}${file.name}`).delete();
  }

  deleteFileKey(fileKey: string): Promise<any> {
    const batch = firebase.firestore().batch();

    batch.delete(
      firebase.firestore().doc(`/db/modules/files/${fileKey}`)
    );

    return batch.commit();
  }

  public async downloadResource(urlO: string): Promise<Blob> {
    const file = await this.http.get<Blob>(
      urlO,
      { responseType: 'blob' as 'json' }).toPromise();
    return file;
  }

  private _typeCheck(upload: Upload, type: string): boolean {
    return upload.file.type.includes(type);
  }

}
