import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlideshowComponent {

  private _photos: any[];
  public total: number = 0;
  public last: number = 0;
  public pos: number = 0;
  public fullscreen = false;
  public dim = '400';

  @Input()
  synap: string = null;

  @Input()
  set item(item: any) {
    if (this.synap && item?.values?.synaps && item.values.synaps[this.synap]) {
      this._photos = Object.keys(item.values.synaps[this.synap])
        .map(key => item.values.synaps[this.synap][key])
        .sort((a, b) => a.order - b.order);
    }
    if (this._photos) {
      this.last = this._photos.length - 1;
      this.total = this._photos.length
    }
  }
  get photos() {
    return this._photos;
  }


  constructor() { }

  previous() {
    if (this.pos > 0) {
      this.pos--;
    } else {
      this.pos = this.last;
    }
  }

  next() {
    if (this.pos < this.last) {
      this.pos++;
    } else {
      this.pos = 0;
    }
  }

  toggleFullscreen() {
    this.fullscreen = !this.fullscreen;
    if (this.fullscreen) {
      this.dim = '1200'
    } else {
      this.dim = '400';
    }
  }
}
