<mat-toolbar class="actionsbg">
    <div>
        <a mat-icon-button (click)="clickBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </a>
        <span>{{db.itemsFiltered.length}} Tasks</span>
    </div>
    <div>
        <button *ngIf="db.isOneOfRoles(['assistant','director', 'admin'])" (click)="goPage('tasks')" mat-icon-button>
            <mat-icon>{{GLOBAL.ICONS.PARAM}}</mat-icon>
        </button>
        <profile></profile>
        <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <div mat-menu-item>
                <mat-icon>{{GLOBAL.ICONS.VERSION}}</mat-icon>
                <span>{{setting.version}}</span>
            </div>
        </mat-menu>
    </div>
</mat-toolbar>
<div class="toolbartop"></div>


<div class="main-container" *ngIf="db.auth.profile?.values">
    <mat-spinner *ngIf="!db.loaded" [strokeWidth]="5"></mat-spinner>
    <div class="filter-buttons">
        <div class="button-row">
            <mat-button-toggle-group appearance="legacy" (change)="changeTime($event)">
                <mat-button-toggle value=""><mat-icon>all_inclusive</mat-icon></mat-button-toggle>
                <mat-button-toggle value="today" [ngClass]="{active: db.actionTime.today}">Today
                    ({{db.actionTime.today}})</mat-button-toggle>
                <!-- <mat-button-toggle value="overdue" [ngClass]="{hot: db.actionTime.overdue}">Overdue
                    ({{db.actionTime.overdue}})</mat-button-toggle> -->
                <mat-button-toggle value="future">Future ({{db.actionTime.future}})</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div class="button-row">
            <mat-button-toggle-group appearance="legacy" (change)="changeType($event)">
                <mat-button-toggle value="" matTooltip="All Actions">
                    <mat-icon>all_inclusive</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle [ngClass]="{split : actionType.split}" *ngFor="let actionType of db.actionTypesArray"
                    [value]="actionType.type" [matTooltip]="setting.getParamValueName('actionTypes', actionType.type)">
                    <mat-icon [ngClass]="actionType.color">{{actionType.type | icon:'actionTypes'}}</mat-icon>
                    <span class="badge">{{actionType.nb}}</span>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>
    <app-table tableKey="main" [item]="actionItem" [items]="db.itemsFilteredOrdered"
        (clickFn)="goItem($event)"></app-table>
</div>