<mat-toolbar>
    <div>
        <button mat-icon-button (click)="goBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <div class="title">
            <mat-icon [ngClass]="item.values.done ? 'active' : 'warm'">
                {{item.values.type | icon:'actionTypes'}}</mat-icon>
            <span *ngIf="item.values.type === 'offer'">{{item.values.done ? (item.values.data.accepted ? 'Offer
                Accepted' :
                'Offer Refused') : 'Pending Offer'}}</span>
            <span *ngIf="item.values.type === 'signLease'">{{item.values.done ? 'Lease Signed' : 'Pending Lease
                Signature'}}</span>
            <span *ngIf="item.values.type === 'signAgree'">{{item.values.done ?'Sale Agreement Signed': 'Pending Sale
                Agreement Signature'}}</span>
            <span *ngIf="item.values.type === 'signDeed'">{{item.values.done ? 'Title Deed Signed' : 'Pending Title Deed
                Signature'}}</span>
        </div>
    </div>
    <div>
        <profile></profile>
    </div>
</mat-toolbar>

<div class="toolbartop"></div>

<div class="mainPage">

    <div class="mdc-layout-grid">
        <div class="mdc-layout-grid__inner">
            <div class="mdc-layout-grid__cell--span-4">
                <mat-card class="backspace cursor" (click)="dateAction()">
                    <mat-list>
                        <mat-list-item>
                            <div matLine style="font-size: 35px;text-align: center;">
                                {{item.values.date | actionDate}}
                            </div>
                            <div matLine style="text-align: center;">
                                <i> {{tools.timeago(item.values.date)}}</i>
                            </div>
                        </mat-list-item>
                    </mat-list>
                </mat-card>
            </div>
            <div class="mdc-layout-grid__cell--span-4">
                <app-contact-card *ngIf="item.values.synaps?.projectsActionsObj?.synaps?.contactsProjects"
                    [contacts]="item.values.synaps?.projectsActionsObj?.synaps?.contactsProjects" class="cursor"
                    (click)="goItem('projects', item.values.synaps?.projectsActionsObj)"></app-contact-card>
                <div class="backspace"></div>
            </div>
            <div class="mdc-layout-grid__cell--span-4">
                <mandate-hcard [mandate]="item.list?.mandate" class="cursor"
                    (click)="goItem('mandates', item.values.synaps.mandatesActionsObj)"></mandate-hcard>
            </div>
        </div>
        <div class="mdc-layout-grid__inner">
            <div class="mdc-layout-grid__cell--span-4">
                <form-builder [item]="item" [values]="item.values" [edit]="!item.values.done"> </form-builder>
            </div>
            <div class="mdc-layout-grid__cell--span-4">
                <form-builder [item]="item" [values]="item.values" [edit]="!item.values.done" section="projectFee">
                </form-builder>
                <mat-card class="upspace" *ngIf="item.values.synaps.projectsActionsObj?.type">
                    <img mat-card-avatar *ngIf="item.values.synaps.projectsActionsObj?.realtor" class="top-right"
                        [src]="item.values.synaps.projectsActionsObj?.realtor | realtor"
                        [matTooltip]="item.values.synaps.projectsActionsObj?.realtor | realtorName" />
                    <mat-list>
                        <mat-list-item>
                            <mat-icon matListIcon>{{GLOBAL.ICONS.DOLLAR}}</mat-icon>
                            <div matLine> Agency Fee: <b>{{item.values.data?.cy | param:'cies'}}
                                    {{calculAgencyComSaleExclVat(item.values.data,
                                    item.values.projectFee,
                                    item.values.synaps.projectsActionsObj.type) |
                                    number}}</b> + VAT =
                                {{item.values.data?.cy | param:'cies'}}
                                {{calculAgencyComSaleInclVat(item.values.data,
                                item.values.projectFee,
                                item.values.synaps.projectsActionsObj.type) |
                                number}}
                            </div>
                            <div matLine *ngIf="item.values.data?.cy && item.values.data?.cy !== 'mur'"> <i><b>Rs
                                    {{calculAgencyComSaleExclVat(item.values.data,item.values.mandateFee,
                                    item.values.synaps.projectsActionsObj.type, setting.exRates[item.values.data?.cy].mur) |
                                    number}}</b> + VAT =
                                Rs
                                {{calculAgencyComSaleInclVat(item.values.data,
                                item.values.mandateFee,
                                item.values.synaps.projectsActionsObj.type, setting.exRates[item.values.data?.cy].mur) |
                                number}}</i>
                            </div>
                        </mat-list-item>
                    </mat-list>
                </mat-card>
            </div>
            <div class="mdc-layout-grid__cell--span-4">
                <form-builder [item]="item" [values]="item.values" [edit]="!item.values.done" section="mandateFee">
                </form-builder>
                <mat-card class="upspace" *ngIf="item.values.synaps.projectsActionsObj?.type">
                    <img mat-card-avatar *ngIf="item.values.synaps.mandatesActionsObj?.realtor" class="top-right"
                        [src]="item.values.synaps.mandatesActionsObj?.realtor | realtor"
                        [matTooltip]="item.values.synaps.mandatesActionsObj?.realtor | realtorName" />
                    <mat-list>
                        <mat-list-item>
                            <mat-icon matListIcon>{{GLOBAL.ICONS.DOLLAR}}</mat-icon>
                            <div matLine> Agency Fee: <b>{{item.values.data?.cy | param:'cies'}}
                                    {{calculAgencyComSaleExclVat(item.values.data,item.values.mandateFee,
                                    item.values.synaps.projectsActionsObj.type) |
                                    number}}</b> + VAT =
                                {{item.values.data?.cy | param:'cies'}}
                                {{calculAgencyComSaleInclVat(item.values.data,
                                item.values.mandateFee,
                                item.values.synaps.projectsActionsObj.type) |
                                number}}
                            </div>
                            <div matLine *ngIf="item.values.data?.cy && item.values.data?.cy !== 'mur'"> <i><b>Rs
                                    {{calculAgencyComSaleExclVat(item.values.data,item.values.mandateFee,
                                    item.values.synaps.projectsActionsObj.type, setting.exRates[item.values.data?.cy].mur) |
                                    number}}</b> + VAT =
                                Rs
                                {{calculAgencyComSaleInclVat(item.values.data,
                                item.values.mandateFee,
                                item.values.synaps.projectsActionsObj.type, setting.exRates[item.values.data?.cy].mur) |
                                number}}</i>
                            </div>
                        </mat-list-item>
                    </mat-list>
                </mat-card>
            </div>
        </div>
    </div>
</div>


<button *ngIf="!item.values.done" mat-fab class="bottom-right pfixed p3" color="primary"
    [disabled]="!item.modified || item.saving" (click)="saveItemAndClose()" matTooltip="Save">
    <mat-icon matListIcon>check</mat-icon>
</button>

<button *ngIf="!item.values.done" mat-fab class="bottom-right pfixed p2" color="basic"
    [disabled]="!item.valid || item.saving || !tools.ifPast(item.values.date)" (click)="refuseAction()"
    matTooltip="Not Accepted">
    <mat-icon matListIcon>thumb_down</mat-icon>
</button>

<button *ngIf="!item.values.done" mat-fab class="bottom-right pfixed" color="accent"
    [disabled]="!item.valid || item.saving || !tools.ifPast(item.values.date)" (click)="acceptAction()"
    matTooltip="Accepted">
    <mat-icon matListIcon>thumb_up</mat-icon>
</button>


<!-- <-- <button *ngIf="item.values.type !== 'offer'" mat-icon-button (click)="dateAction()">
    <mat-icon>event</mat-icon>
</button> -->

<!-- DELETE -->
<button *ngIf="!item.values.done" mat-fab class="bottom-left pfixed" color="basic" (click)="deleteAction()"
    matTooltip="Cancel">
    <mat-icon matListIcon>delete</mat-icon>
</button>