import { MainItem, Field, Lang, Alert, Address } from '../main/main.model';


export class Profile extends MainItem {

    values: {
        $key: string,
        ref: number,
        created: firebase.firestore.FieldValue,
        createdBy: string,
        updated: firebase.firestore.FieldValue,
        updatedBy: string,
        alerts: Alert[],

        synaps: any,

        status: 'pending' | 'active' | 'blocked';
        provider: 'password' | 'google.com';
        firstname: string;
        lastname: string;
        fullname: string;
        initial: string;
        agencies: string[];
        agency: string;
        email: string;
        tel: string;
        desc: Lang;
        extPhoto: string;
        role: string;
        subRoles: { [name: string]: boolean };
        // photo: FileRecord;
    }

    constructor(
        values: any = null
    ) {
        super(
            'users',
            {
                status: new Field({ default: 'pending' }),
                provider: new Field(),
                firstname: new Field({ label: 'Firstname', validations: { required: true } }),
                lastname: new Field({ label: 'Lastname', validations: { required: true } }),
                fullname: new Field({
                    input: 'auto',
                    autoFn: {
                        toFB: (fields) => { return (fields.provider == 'password' || fields.status == 'pending') ? fields.firstname + ' ' + fields.lastname : fields.fullname },
                        // fromFB: (fields) => { return fields.fullname }
                    }
                }),
                initial: new Field(),
                email: new Field({ label: 'Email', validations: { required: true, email: true } }),
                tel: new Field({ label: 'Tel' }),
                desc: new Field({ multiLang: true, input: 'textarea', label: 'Description' }),
                extPhoto: new Field(),
                role: new Field({ label: 'Role', validations: { required: true }, input: 'select', inputOptions: { param: 'userRoles' } }),
                subRoles: new Field({
                    label: 'Specialities', input: 'select',
                    inputOptions: { param: 'userSpecialities', multi: true },
                    show: (data: any) => data && data.role === 'realtor',
                }),
                agencies: new Field({ label: 'Agencies', validations: { required: true }, input: 'select', inputOptions: { param: 'agencies', multi: true } }),
                agency: new Field({ label: 'Main Agency', validations: { required: true }, input: 'select', inputOptions: { param: 'agencies' } }),
                photo: new Field({ input: 'image', label: 'Photo' }),
            },
            {
                password: {
                    title: 'User Details',
                    fields: ['firstname', 'lastname', 'email', 'tel', 'agencies', 'agency', 'role', 'subRoles']
                },
                external: {
                    title: 'User Details',
                    fields: ['tel', 'agencies', 'agency', 'role', 'subRoles']
                },
                new: {
                    title: 'User Details',
                    fields: ['firstname', 'lastname', 'email', 'agencies', 'role', 'subRoles', 'agencies', 'agency']
                },
            },
            {},
            { path: 'users/', filename: () => 'user' },
            null
        );
        if (values) {
            this.fromFB(values);
        }

    }
}

export class Agency extends MainItem {

    values: {
        $key: string,
        ref: number,
        created: firebase.firestore.FieldValue,
        createdBy: string,
        updated: firebase.firestore.FieldValue,
        updatedBy: string,
        alerts: Alert[],

        synaps: any,

        id: string,
        status: 'active' | 'blocked';
        name: string;
        email: string;
        tel: string;
        address: Address;
        desc: Lang;
        website: string;
        facebook: string;
        linkedin: string;
        tiktok: string;
        youtube: string;
        // photo: FileRecord;
    }

    constructor(
        values: any = null,
    ) {
        super(
            'agencies',
            {
                id: new Field({ label: 'ID', validations: { required: true } }),
                status: new Field({ default: 'active' }),
                name: new Field({ label: 'Name', validations: { required: true } }),
                email: new Field({ label: 'Email', validations: { required: true, email: true } }),
                tel: new Field({ label: 'Tel' }),
                address: new Field({
                    label: 'Address',
                    input: 'objectSub',
                    sub: new AgencyAddressFields(),
                }),
                desc: new Field({ multiLang: true, input: 'textarea', label: 'Description' }),
                website: new Field({ default: 'https://www.nestenn.mu' }),
                facebook: new Field({ default: 'https://www.facebook.com/NestennIleMaurice/' }),
                linkedin: new Field({ default: 'https://www.linkedin.com/company/nestenn-immobilier-ile-maurice/' }),
                tiktok: new Field({ default: 'https://www.tiktok.com/@nestennimmobiliermaurice' }),
                youtube: new Field({ default: 'https://www.youtube.com/channel/UCF26zh8LJxyTtGIXHVU1Nww' }),
                photo: new Field({ input: 'image', label: 'Photo' }),
            },
            {
                main: {
                    title: 'Agency Details',
                    fields: ['name', 'id', 'email', 'tel', 'address', 'desc', 'website', 'facebook', 'linkedin', 'tiktok', 'youtube']
                },
                photo: {
                    title: 'Agency Photo',
                    fields: ['photo',]
                },
            },
            {},
            { path: 'agencies/', filename: () => 'agency' },
            null
        );
        if (values) {
            this.fromFB(values);
        }

    }
}

export class AgencyAddressFields {
    unitNb = new Field({ label: 'Unit Number' });
    streetNb = new Field({ label: 'Street Number' });
    street = new Field({ label: 'Street' });
    city = new Field({ label: 'City' });
    postcode = new Field({ label: 'Postcode' });
    // district = new Field({ label: 'Region', show: () => false });
    country = new Field({ label: 'Country', default: 'Mauritius', show: () => false });
    gps = new Field({
        input: 'gps', label: 'GPS',
        show: () => false
    });
    // sectors = new Field({
    //     input: 'arrayValue',
    //     show: () => false
    // });
    // sector = new Field({ show: () => false });
}
