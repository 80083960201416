import { Alert, Field, MainItem } from "../main/main.model";
import { BehaviorSubject } from 'rxjs';


export interface FileRecord {
    name: string;
    path: string;
    type: string;
    url: string;
    order?: number;
    // info?: any;
    $key?: string;
}

export class Upload {
    file: File;
    name: string;
    path: string;
    progress: number;
    private _progress = new BehaviorSubject<number>(0);
    progress$ = this._progress.asObservable();

    constructor(file: File, path: string, filename: string) {
        this.file = file;
        this.name = filename;
        this.path = path;
        this.progress = 0;
    }

    setProgress(value: number) {
        this._progress.next(value);
    }
}

export class FileCRM extends MainItem {

    values: {
        $key: string,
        ref: number,
        created: firebase.firestore.FieldValue,
        createdBy: string,
        updated: firebase.firestore.FieldValue,
        updatedBy: string,
        alerts: Alert[],
        synaps: any,

        name: string;
        path: string;
        type: string;
        url: string;

        info?: any;
    }

    constructor(
        values: any = null,
    ) {
        super(
            'files',
            {

                name: new Field(),
                path: new Field(),
                type: new Field(),
                url: new Field(),
                // info: new Field({
                //     input: 'objectSub',
                //     sub: {
                //         online: new Field({
                //             input: 'boolean', default: true,
                //         }),
                //         portal: new Field({
                //             input: 'boolean', default: true,
                //         }),
                //     }
                // }),
            },
            // Forms
            {
                main: {
                    title: null,
                    fields: ['name', 'path', 'type', 'url']
                },
            },
            // Tables
            {
                main: {
                    title: 'File List',
                    columns: [
                        { field: 'ref', type: 'string', label: 'Ref', options: { prefix: 'F' } },
                        { field: 'name', type: 'string', label: 'Name' },
                        { field: 'type', type: 'string', label: 'Type' },
                        { field: 'url', type: 'string', label: 'URL' },
                    ],
                },
            },
            // Config File
            {
                path: 'files/', filename: (data) => {
                    let filename = 'file-';
                    filename += 'F' + data.ref;
                    return filename
                }
            },
        );
        if (values) {
            this.fromFB(values);
        }

    }

}