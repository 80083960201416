import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { GlobalConstants } from 'functions/src/shared/global-constants';
import { MainsComponent } from 'src/common/pages/mains/mains.component';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { SelectService } from 'src/common/services/select/select.service';
import { SettingService } from 'src/common/services/setting/setting.service';
import { Action } from 'src/services/action/action.model';
import { MyActionService } from 'src/services/action/action.service';

@Component({
  selector: 'app-mytasks',
  templateUrl: './mytasks.component.html',
  styleUrls: ['./mytasks.component.scss']
})
export class MytasksComponent extends MainsComponent {

  // public newRequests: ActionService = null;
  public actionItem = new Action(null, this.setting);
  public GLOBAL = new GlobalConstants();

  // public preview: any = null;

  constructor(
    protected afs: AngularFirestore,
    public db: MyActionService,
    public settings: SettingService,
    public backURL: BackurlService,
    protected router: Router,
    public setting: SettingService,
    public select: SelectService,
    protected fb: FormBuilder,
  ) {
    super(setting, db, select, router, fb, backURL);

    this.sortLocalForm = this.fb.group({
      filters: this.fb.group({
        type: [],
        time: ['today']
      }),
    });

  }


  changeTime($event: any) {
    this.sortLocalForm.get('filters.time').setValue($event.value);
    this.db.setLocalFilter(this.sortLocalForm.value);
    this.db.refreshActionTypes(this.sortLocalForm.value.filters.time);
    if (!this.db.actionTypes[this.sortLocalForm.value.filters.type]) {
      this.changeType('');
    }
  }

  changeType($event: any) {
    this.sortLocalForm.get('filters.type').setValue($event.value);
    this.db.setLocalFilter(this.sortLocalForm.value);
  }


  goItem(item: any) {
    this.backURL.addURL('/');
    if (item.project && item.project.$key) {
      this.router.navigate([`/projects/${item.project.$key}`]);
    } else if (item.mandate && item.mandate.$key) {
      this.router.navigate([`/mandates/${item.mandate.$key}`]);
    }
  }


}
