<mat-toolbar class="projectsbg">

    <div>
        <a mat-icon-button (click)="clickBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </a>
        <span>{{db.itemsFiltered.length}} Projects</span>
    </div>
    <!-- <mat-spinner [strokeWidth]="5" *ngIf="db.serverLoading"></mat-spinner>
    <mat-spinner [strokeWidth]="5" color="accent" *ngIf="db.filterLoading"></mat-spinner> -->

    <div class="form">
        <form [formGroup]="searchForm" novalidate>
            <mat-form-field appearance="fill">
                <mat-label>Search</mat-label>
                <input matInput formControlName="search">
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
        </form>
    </div>
    <div>
        <button mat-icon-button *ngIf="!db.itemsPageConfig.referral" (click)="toggleReferral()">
            <mat-icon>{{GLOBAL.ICONS.REFERRAL}}</mat-icon>
        </button>
        <button mat-icon-button *ngIf="db.itemsPageConfig.referral" (click)="toggleReferral()">
            <mat-icon>{{GLOBAL.ICONS.PROJECT}}</mat-icon>
        </button>
        <profile></profile>
    </div>

</mat-toolbar>
<div class="toolbartop"></div>

<div class="main-container-flex split s20">
    <mat-spinner *ngIf="!db.loaded" [strokeWidth]="5"></mat-spinner>
    <app-table *ngIf="!db.itemsPageConfig.referral" class="main" [offset]="db.itemsPageConfig.scrollIndex"
        [tableKey]="db.isRole('realtor') ? 'main' : 'realtor'" [item]="item" [items]="db.itemsFilteredOrdered"
        (sortChange)="sortChange($event)" (clickFn)="goItem($event)" [sort]="db.localFilters.sort"
        (scrollFn)="scrollItems($event)">
    </app-table>
    <app-table *ngIf="db.itemsPageConfig.referral" class="main" [offset]="db.itemsPageConfig.scrollIndex"
        [tableKey]="db.isRole('realtor') ? 'referral' : 'referralRealtor'" [item]="item" [items]="db.itemsFilteredOrdered"
        (sortChange)="sortChange($event)" (clickFn)="goItem($event)" [sort]="db.localFilters.sort"
        (scrollFn)="scrollItems($event)">
    </app-table>
    <div class="side">
        <mat-list class="h s">
            <mat-list-item class="cursor" (click)="filterStatus('N')">
                <mat-icon matListIcon class="projects">{{GLOBAL.ICONS.PROJECT}}</mat-icon>
                <h3 matLine
                    *ngIf="db.loaded && !(sortServerForm.value.multiFilters.status.includes('deal') || sortServerForm.value.multiFilters.status.includes('failed'))">
                    {{db.items.length}}</h3>
            </mat-list-item>
            <mat-list-item class="cursor" (click)="filterStatus('deal')">
                <mat-icon matListIcon class="active">{{GLOBAL.ICONS.DEAL}}</mat-icon>
                <h3 matLine
                    *ngIf="db.loaded && sortServerForm.value.multiFilters.status.includes('deal')">
                    {{db.items.length}}</h3>
            </mat-list-item>
            <mat-list-item class="cursor" (click)="filterStatus('failed')">
                <mat-icon matListIcon>{{GLOBAL.ICONS.FAILED}}</mat-icon>
                <h3 matLine
                    *ngIf="db.loaded && sortServerForm.value.multiFilters.status.includes('failed')">
                    {{db.items.length}}</h3>
            </mat-list-item>
        </mat-list>
        <mat-list class="h s" *ngIf="db.loaded">
            <mat-list-item *ngIf="db.summary.new" class="cursor" (click)="filterStatus('new')">
                <mat-icon matListIcon class="hot">{{GLOBAL.ICONS.NEW}}</mat-icon>
                <h3 matLine> {{db.summary.new}} </h3>
            </mat-list-item>
            <mat-list-item *ngIf="db.summary.discovery" class="cursor" (click)="filterStatus('discovery')">
                <mat-icon matListIcon class="warm">{{GLOBAL.ICONS.DISCOVERY}}</mat-icon>
                <h3 matLine> {{db.summary.discovery}} </h3>
            </mat-list-item>
            <mat-list-item *ngIf="db.summary.pause" class="cursor" (click)="filterStatus('pause')">
                <mat-icon matListIcon class="cold">{{GLOBAL.ICONS.PAUSE}}</mat-icon>
                <h3 matLine> {{db.summary.pause}} </h3>
            </mat-list-item>
        </mat-list>
        <mat-list class="h s" *ngIf="db.loaded">
            <mat-list-item *ngIf="db.summary.hot" class="cursor" (click)="filterStatus('active', 'hot')">
                <mat-icon matListIcon class="hot">{{GLOBAL.ICONS.PROJECT}}</mat-icon>
                <h3 matLine> {{db.summary.hot}} </h3>
            </mat-list-item>
            <mat-list-item *ngIf="db.summary.warm" class="cursor" (click)="filterStatus('active', 'warm')">
                <mat-icon matListIcon class="warm">{{GLOBAL.ICONS.PROJECT}}</mat-icon>
                <h3 matLine> {{db.summary.warm}} </h3>
            </mat-list-item>
            <mat-list-item *ngIf="db.summary.cold" class="cursor" (click)="filterStatus('active', 'cold')">
                <mat-icon matListIcon class="cold">{{GLOBAL.ICONS.PROJECT}}</mat-icon>
                <h3 matLine> {{db.summary.cold}} </h3>
            </mat-list-item>
        </mat-list>
        <mat-list class="h s" *ngIf="db.loaded">
            <mat-list-item *ngIf="db.summary.offer" class="cursor" (click)="filterStatus('offer')">
                <mat-icon matListIcon class="hot">{{GLOBAL.ICONS.OFFER}}</mat-icon>
                <h3 matLine> {{db.summary.offer}} </h3>
            </mat-list-item>
            <mat-list-item *ngIf="db.summary.signAgree" class="cursor" (click)="filterStatus('signAgree')">
                <mat-icon matListIcon class="warm">{{GLOBAL.ICONS.SIGNAGREE}}</mat-icon>
                <h3 matLine> {{db.summary.signAgree}} </h3>
            </mat-list-item>
            <mat-list-item *ngIf="db.summary.signDeed" class="cursor" (click)="filterStatus('signDeed')">
                <mat-icon matListIcon class="warm">{{GLOBAL.ICONS.SIGNDEED}}</mat-icon>
                <h3 matLine> {{db.summary.signDeed}} </h3>
            </mat-list-item>
            <mat-list-item *ngIf="db.summary.signLease" class="cursor" (click)="filterStatus('signLease')">
                <mat-icon matListIcon class="warm">{{GLOBAL.ICONS.SIGNLEASE}}</mat-icon>
                <h3 matLine> {{db.summary.signLease}} </h3>
            </mat-list-item>
            <mat-list-item *ngIf="db.summary.payment" class="cursor" (click)="filterStatus('payment')">
                <mat-icon matListIcon class="hot">{{GLOBAL.ICONS.PAYMENT}}</mat-icon>
                <h3 matLine> {{db.summary.payment}} </h3>
            </mat-list-item>
        </mat-list>
        <form [formGroup]="sortServerForm" novalidate>

            <mat-form-field appearance="fill"
                *ngIf="!db.itemsPageConfig.referral && db.auth.profile.values.agencies.length > 1 && db.auth.isOneOfRoles(['admin', 'director', 'assistant'])">
                <mat-label>Agency</mat-label>
                <mat-select formControlName="agency">
                    <mat-option *ngFor="let param of 'userAgencies' | params" [value]="param.id">
                        {{param.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill"
                *ngIf="!db.itemsPageConfig.referral && db.auth.isOneOfRoles(['admin', 'director', 'assistant'])">
                <mat-label>Realtor</mat-label>
                <mat-select formControlName="realtor">
                    <mat-option value="">
                        All Agents
                    </mat-option>
                    <mat-option *ngFor="let param of setting.realtorsAgency[sortServerForm.controls['agency'].value]"
                        [value]="param.id">
                        {{param.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field formGroupName="filters" appearance="fill"
                *ngIf="db.itemsPageConfig.referral && db.auth.profile.values.agencies.length > 1 && db.auth.isOneOfRoles(['admin', 'director', 'assistant'])">
                <mat-label>Referral Agency</mat-label>
                <mat-select formControlName="referralAgency">
                    <mat-option *ngFor="let param of 'userAgencies' | params" [value]="param.id">
                        {{param.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field formGroupName="filters" appearance="fill"
                *ngIf="db.itemsPageConfig.referral && !select.active && db.auth.isOneOfRoles(['admin', 'director', 'assistant', 'marketing'])">
                <mat-label>Referral Agent</mat-label>
                <mat-select formControlName="referral">
                    <mat-option value="">
                        All Agents
                    </mat-option>
                    <mat-option *ngFor="let param of setting.realtorsAgency[sortServerForm.get('filters.referralAgency').value]"
                        [value]="param.id">
                        {{param.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- <mat-form-field formGroupName="multiFilters" appearance="fill">
                <mat-label>Status</mat-label>
                <mat-select formControlName="status" [multiple]="true">
                    <mat-option *ngFor="let param of 'projectStatus' | params" [value]="param.id">
                        {{param.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field> -->
        </form>

        <form [formGroup]="sortLocalForm" novalidate>

            <mat-form-field formGroupName="filters" appearance="fill">
                <mat-label>Type</mat-label>
                <mat-select formControlName="type">
                    <mat-option value="N">No selection</mat-option>
                    <mat-option *ngFor="let param of 'projectTypes' | params" [value]="param.id">
                        {{param.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field formGroupName="arrayFilters" appearance="fill">
                <mat-label>Unit Type</mat-label>
                <mat-select formControlName="unitTypes">
                    <mat-option value="">No selection</mat-option>
                    <mat-option *ngFor="let param of 'unitTypes' | params" [value]="param.id">
                        {{param.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>

    </div>
</div>

<!-- <button mat-fab class="bottom-right pfixed" [ngClass]="{p2: select.active}" color="basic" (click)="addItem()">
    <mat-icon matListIcon>add</mat-icon>
</button> -->

<button *ngIf="select.active" mat-fab class="bottom-right pfixed" (click)="select.done()">
    <mat-icon matListIcon>done</mat-icon>
</button>