<ng-container *ngIf="action.done">
    <mat-icon class="active" *ngIf="action.ad">{{GLOBAL.ICONS.ONLINE}}</mat-icon>
    <mat-icon *ngIf="!action.ad">{{GLOBAL.ICONS.OFFLINE}}</mat-icon>
    <mat-icon *ngIf="!action.priceVisible && action.ad">{{GLOBAL.ICONS.HIDEPRICE}}</mat-icon>
</ng-container>


<ng-container *ngIf="!action.done">
    <mat-card *ngIf="moduleItem.values.synaps?.unitMandatesObj?.errors" class="cursor"
        (click)="goItem('units', moduleItem.values.synaps.unitMandatesObj)">
        <mat-list>
            <mat-list-item>
                <mat-icon matListIcon class="hot">{{GLOBAL.ICONS.ALERT}}</mat-icon>
                <div matLine>
                    Property Parameters:
                    {{moduleItem.values.synaps?.unitMandatesObj?.errors}} Missing Infos
                </div>
            </mat-list-item>
        </mat-list>
    </mat-card>
    <form-builder [item]="item" [values]="item.values" [edit]="true"></form-builder>

    <div class="actionButtons" *ngIf="isRealtor()">
        <button mat-icon-button color="accent" [disabled]="!item.modified || item.saving" (click)="saveAction()">
            <mat-icon>check</mat-icon>
        </button>
        <button mat-icon-button class="active"
            [disabled]="!item.valid || item.saving || !!(item.values.data?.ad && moduleItem.values.synaps?.unitMandatesObj?.errors)"
            (click)="validAction()">
            <mat-icon>task_alt</mat-icon>
        </button>
        <button mat-icon-button (click)="dateAction()">
            <mat-icon>event</mat-icon>
        </button>
    </div>
</ng-container>