import { AngularFirestore, DocumentData, QuerySnapshot } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { MainItem, Item } from './main.model';
import { AuthService } from '../auth/auth.service';
import * as firebase from 'firebase/app';
import createAlias from '../../../../functions/src/shared/alias';
import { Synap, Synaps } from 'functions/src/shared/synaps-config';
import { getModuleListData } from 'functions/src/shared/list';


export interface PageConfig {
  edit: boolean;
  editTab: number;
  mainTab: number;
  loaded: boolean;
}


export class MainService {

  public items$: Observable<any[]> = null
  private _itemsSubcription: any;
  public refresh: BehaviorSubject<any> = new BehaviorSubject<any>(null); // Create a BehaviorSubject
  public items: any[] = [];
  public itemsFiltered: any[] = [];
  public itemsFilteredOrdered: any[] = [];
  public loaded = false;
  public listMode = true;
  public refMode = false;

  public mainDispach = new Synaps().dispatch;

  protected _filters: {
    search: string,
    server: {
      filters?: any,
      multiFilters?: any,
      arrayFilters?: any,
      equalSup?: any,
      equalInf?: any,
      toDays?: number,
      year?: string,
      agency?: string,
      realtor?: string,
      sort?: { active: string, direction: 'asc' | 'desc' },
    },
    local: {
      localSearch?: string,
      gmap?: any,
      filters?: any,
      multiFilters?: any,
      arrayFilters?: any,
      synaps?: any,
      equalSup?: any,
      equalInf?: any,
      budget?: any,
      sort?: { active: string, direction: 'asc' | 'desc' },
    },
  } = {
      search: null,
      server: {
      },
      local: {
        localSearch: '',
        sort: { active: 'ref', direction: 'desc' },
      },
    };
  public serverLoading: boolean = false;
  public filterLoading: boolean = false;
  // public item$: Observable<Item> = null
  // public item: Item = null;

  protected _readRoles: { [role: string]: boolean } = { all: true };
  protected _editRoles: { [role: string]: boolean } = { all: true };
  protected _addRoles: { [role: string]: boolean } = { all: true };
  protected _deleteRoles: { [role: string]: boolean } = { admin: true };


  private _itemPages: {
    [key: string]: {
      values: any;
      pageConfig: PageConfig;
    }
  } = {};

  private _itemsPage: any = {};

  // refreshed: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public auth: AuthService,
    protected afs: AngularFirestore,
    public moduleName: string = 'main',
    public moduleListName: string = 'mains',
    public itemClass = Item,
  ) {
  }


  //************** INIT VALUES **********************/ 

  protected _initItems() {
    this.items$ = this._colWithIds(`/list/modules/${this.moduleListName}`, (ref) => this._queryFn(ref));
  }

  public subscribe(): Promise<any> {


    return new Promise(resolve => {


      if (this._itemsSubcription) {
        this._itemsSubcription.unsubscribe();
      }

      if (this.canRead) {
        this.loaded = false;
        this._initItems();

        this._itemsSubcription = this.items$.subscribe(items => {
          this.items = items;
          this._afterRefreshList();
          this.loaded = true;
          // Do not filter if looking for reference
          if (!(this._filters.server.filters && this._filters.server.filters.ref)) {
            this._localFilter();
          } else {
            this.itemsFilteredOrdered = this.items;
          }
          this.refresh.next(true);
          resolve(true);
        });
      } else {
        this.items$ = null;
        this.items = [];
        this._afterRefreshList();
        this.itemsFiltered = [];
        resolve(true);
      }


      this.serverLoading = false;
    });
  }

  protected _queryFn(ref): any {

    let query = ref;

    if (this._filters.server.filters) {
      if (this._filters.server.filters.ref) {
        query = query.where('ref', '==', this._filters.server.filters.ref);
        return query;
      }
      for (let param in this._filters.server.filters) {
        if (typeof this._filters.server.filters[param] == "boolean" || this._filters.server.filters[param]) {
          query = query.where(param, '==', this._filters.server.filters[param]);
        }
      }
    }
    if (this._filters.server.multiFilters) {
      for (let param in this._filters.server.multiFilters) {
        if (this._filters.server.multiFilters[param] && this._filters.server.multiFilters[param].length) {
          query = query.where(param, 'in', this._filters.server.multiFilters[param]);
        }
      }
    }
    if (this._filters.server.arrayFilters) {
      for (let param in this._filters.server.arrayFilters) {
        if (this._filters.server.arrayFilters[param] && this._filters.server.arrayFilters[param].length) {
          query = query.where(param, 'array-contains', this._filters.server.arrayFilters[param]);
        }
      }
    }
    if (this._filters.server.equalSup) {
      for (let param in this._filters.server.equalSup) {
        if (this._filters.server.equalSup[param]) {
          query = query.where(param, '>=', this._filters.server.equalSup[param]);
        }
      }
    }
    if (this._filters.server.year) {
      const from = new Date(this._filters.server.year + '-01-01');
      const to = new Date(this._filters.server.year + '-12-31');
      query = query.where('date', '>=', from)
        .where('date', '<=', to);
    }
    if (this._filters.server.toDays) {
      let toDays = new Date();
      toDays.setDate(toDays.getDate() + this._filters.server.toDays);
      query = query.where('date', '<=', toDays);
    }
    if (this._filters.server.agency) {
      query = query.where('agency', '==', this._filters.server.agency);
    }
    if (this._filters.server.realtor) {
      query = query.where('realtor', '==', this._filters.server.realtor);
    }
    if (this._filters.server.sort) {
      query = query.orderBy(this._filters.server.sort.active, this._filters.server.sort.direction);
    }

    return query;
  }

  protected _afterRefreshList() {
  }

  public initItem(page: any) {
    if (this.canRead) {
      if (page._$key && this._itemPages[page._$key]) {
        Object.assign(page.pageConfig, this._itemPages[page._$key].pageConfig);
        page.values = this._itemPages[page._$key].values;
        // page.item = new this.itemClass(null, page.setting);
      } else {
        // page.item = new this.itemClass(null, page.setting);
      }
    }
  }

  public getData(key: string, module = this.moduleListName): Promise<any> {
    if (this.canRead) {
      return firebase.firestore().doc(`/db/modules/${module}/${key}`).get().then(doc => {
        if (doc.exists) {
          return doc.data();
        }
        return Promise.resolve(null);
      });
    }
    return Promise.resolve(null);
  }

  public getDataObs(key: string, module = this.moduleListName): Observable<any> {
    if (this.canRead) {
      return this.afs.doc(`/db/modules/${module}/${key}`).snapshotChanges();
    }
  }

  public async getDatabyRef(ref: string): Promise<any> {
    const querySnapshot: QuerySnapshot<DocumentData> = await firebase.firestore().collection(`/db/modules/${this.moduleListName}/`).where('ref', '==', ref).get();
    const list: any[] = [];

    querySnapshot.forEach(function (doc: any) {
      list.push(doc.data());
    });

    if (list.length) {
      return list[0];
    }
    return null;
  }




  public bindItemUpdates(item: Item): Observable<any> {

    return this.afs.doc(`/db/modules/${this.moduleListName}/${item.values.$key}`).snapshotChanges().pipe(
      map(a => {
        if (a.payload.exists) {
          item.fromFB(a.payload.data(), true);
        }
      })
    );
  }

  public async getListItembyRef(ref: string): Promise<any> {
    const querySnapshot: QuerySnapshot<DocumentData> = await firebase.firestore().collection(`/list/modules/${this.moduleListName}/`).where('ref', '==', ref).get();
    const list: any[] = [];

    querySnapshot.forEach(function (doc: any) {
      list.push(doc.data());
    });

    if (list.length) {
      return list[0];
    }
    return null;
  }

  //************** SAVE VALUES **********************/ 

  saveNewItem(item: MainItem): Promise<string> {
    if (this.canEdit) {


      const batch = firebase.firestore().batch();

      const newDocRef = firebase.firestore().collection(`/db/modules/${this.moduleListName}`).doc();

      item.values.$key = newDocRef.id;
      // Object.assign(item.values, values);

      const newData = Object.assign(
        item.toFB('new'),
        {
          created: firebase.firestore.FieldValue.serverTimestamp(),
          createdBy: this.auth.uid,
          updated: firebase.firestore.FieldValue.serverTimestamp(),
          updatedBy: this.auth.uid,
        },
        this.refMode ? {
          actionSynap: {
            date: firebase.firestore.FieldValue.serverTimestamp(),
            name: 'none',
            origin: this.moduleListName,
          }
        } : {},
      )

      batch.set(
        newDocRef,
        newData
      );

      if (this.listMode) {
        batch.set(
          firebase.firestore().doc(`/list/modules/${this.moduleListName}/${newDocRef.id}`),
          getModuleListData(this.moduleListName, newData)
        );
      }

      item.saving = true;
      return batch.commit().then(() => {
        this._resetModify(item.fields, item.modify);
        // item.valid = false;
        item.modified = false;
        item.saving = false;
        return (newDocRef.id);
      });
    }
  }

  saveItem(item: MainItem): Promise<any> {
    if (this.canEdit) {
      const batch = firebase.firestore().batch();

      batch.set(
        firebase.firestore().doc(`/db/modules/${this.moduleListName}/${item.values.$key}`),
        Object.assign(
          item.toFB('save'),
          {
            updated: firebase.firestore.FieldValue.serverTimestamp(),
            updatedBy: this.auth.uid,
          }
        ),
        { merge: true }
      );

      if (this.listMode) {
        batch.set(
          firebase.firestore().doc(`/list/modules/${this.moduleListName}/${item.values.$key}`),
          getModuleListData(this.moduleListName,
            Object.assign(
              item.toFB('new'),
              {
                updated: firebase.firestore.FieldValue.serverTimestamp(),
                updatedBy: this.auth.uid,
              }
            ))
        );
      }

      item.saving = true;
      return batch.commit().then(() => {
        // Put back modified value and Main Validation value to FALSE
        this._resetModify(item.fields, item.modify);
        // item.valid = false;
        item.modified = false;
        item.saving = false;
      });
    }
  }

  saveItemSynapInfo(item: MainItem, synap: string): Promise<any> {
    const batch = firebase.firestore().batch();

    batch.set(
      firebase.firestore().doc(`/db/modules/${item.module}/${item.values.$key}`),
      {
        synaps: { [synap]: item.values.synaps[synap] },
        updated: firebase.firestore.FieldValue.serverTimestamp(),
        updatedBy: this.auth.uid,
      },
      { merge: true }
    );

    return batch.commit();
  }


  // Carefull no check
  async manualSave(item: MainItem, $key: string, data: any, list: boolean = true): Promise<any> {
    if (this.canEdit && $key && data) {

      const batch = firebase.firestore().batch();

      data.updated = firebase.firestore.FieldValue.serverTimestamp();
      data.updatedBy = this.auth.uid;
      // data.listUpdated = firebase.firestore.FieldValue.serverTimestamp();

      data.updatedFields = {}
      for (const field of Object.keys(data)) {
        data.updatedFields[field] = firebase.firestore.FieldValue.serverTimestamp();
      }

      if (list) {
        data.listUpdated = firebase.firestore.FieldValue.serverTimestamp();
      }

      batch.set(
        firebase.firestore().doc(`/db/modules/${item.module}/${$key}`),
        data,
        { merge: true }
      );



      return batch.commit();
    }
    return false;
  }


  public startSaving(item: MainItem) {
    item.saving = true;
  }




  // MANAGE SYNAPS

  async saveItemSynaps(item: MainItem, synap: string, newSynaps: any): Promise<any> {

    item.saving = true;

    // start future server function
    // const batch = firebase.firestore().batch();

    // const module = itemTmp.module;
    // const item = await this.getData(itemTmp.values.$key, module);


    if (!item.values.synaps) {
      item.values.synaps = {};
    }
    if (!item.values.synaps[synap]) {
      item.values.synaps[synap] = {};
    }

    let addMandatesActions = false;
    // Check Deleted
    for (const connector in item.values.synaps[synap]) {
      if (!newSynaps[connector]) {
        item.values.synaps[synap][connector] = firebase.firestore.FieldValue.delete();

        //Check sync mandatesActions for actions module only
        if (item.module === 'actions' &&
          (synap === 'offer' || synap === 'signAgree' || synap === 'signDeed' || synap === 'signLease')) {
          if (item.values.synaps.mandatesActions) {
            for (const mandateKey in item.values.synaps.mandatesActions) {
              item.values.synaps.mandatesActions[mandateKey] = firebase.firestore.FieldValue.delete();
            }
            addMandatesActions = true;
          }
        }
      }
    }
    // Check New
    for (const connector in newSynaps) {
      if (!(item.values.synaps[synap] && item.values.synaps[synap][connector])) {
        item.values.synaps[synap][connector] = { newSynap: true };

        //Check sync mandatesActions
        if (item.module === 'actions' && newSynaps[connector].mandate && newSynaps[connector].mandate.$key &&
          (synap === 'offer' || synap === 'signAgree' || synap === 'signDeed' || synap === 'signLease')) {
          if (!item.values.synaps.mandatesActions) {
            item.values.synaps.mandatesActions = {};
          }
          item.values.synaps.mandatesActions[newSynaps[connector].mandate.$key] = { newSynap: true };
          addMandatesActions = true;
        }
      }
    }




    // Update the Item

    const newSynap = Object.assign({ [synap]: item.values.synaps[synap] }, addMandatesActions ? { mandatesActions: item.values.synaps.mandatesActions } : {});

    return firebase.firestore().doc(`/db/modules/${this.moduleListName}/${item.values.$key}`).set(
      {
        synaps: newSynap
      },
      { merge: true }
    ).then(() => {
      item.saving = false;
    });
  }

  // private _getAndAddNewSynaps(
  //   batch: any,
  //   synap: string,
  //   item1: any, item2: any,
  //   module1: string, module2: string,
  //   remove = false
  // ) {

  //   const dispatch1 = this.mainDispach[module1];
  //   const dispatch2 = this.mainDispach[module2];

  //   let alias1: any = firebase.firestore.FieldValue.delete();
  //   let alias2: any = firebase.firestore.FieldValue.delete();
  //   if (!remove) {
  //     alias1 = createAlias(item1, module1);
  //     alias2 = createAlias(item2, module2);
  //   }

  //   // Add item2 to item1
  //   this._dispatchSynap(
  //     batch,
  //     synap,
  //     item1.synaps, item2.synaps,
  //     item1.$key, item2.$key,
  //     module1,
  //     alias2,
  //     dispatch1[synap], dispatch2[synap],
  //     [],
  //     remove
  //   );


  //   // Add item1 to item2
  //   this._dispatchSynap(
  //     batch,
  //     synap,
  //     item2.synaps, item1.synaps,
  //     item2.$key, item1.$key,
  //     module2,
  //     alias1,
  //     dispatch2[synap], dispatch1[synap],
  //     [],
  //     remove
  //   );

  //   // item.synaps[synap][connector] = this._getAliasWithDependantSynaps(newConnectedItem, synap, item.synapConfig)

  //   // Check If needs to dispath
  //   // for (const syn of mainDispath) 

  // }

  // private _dispatchSynap(
  //   batch: any,
  //   synap: string,
  //   synaps1: any, synaps2: any,
  //   key1: any, key2: any,
  //   module1: string,
  //   alias: any,
  //   dispatch1: Synap, dispatch2: Synap,
  //   path: { synap: string, connector: string }[],
  //   remove = false) {


  //   batch.set(
  //     firebase.firestore().doc(`/db/modules/${module1}/${key1}`),
  //     {
  //       actionSynap: {
  //         name: 'none',
  //         origin: 'front',
  //         date: firebase.firestore.FieldValue.serverTimestamp(),
  //       },
  //       listUpdated: firebase.firestore.FieldValue.serverTimestamp(),
  //       synaps: { [synap]: { [key2]: remove ? alias : this._addDependantSynaps(alias, synaps2, dispatch1, path) } }
  //     }, { merge: true })



  //   if (dispatch2.synaps) {
  //     for (const dispatchSynap in dispatch2.synaps) {
  //       if (synaps1 && synaps1[dispatchSynap]) {
  //         for (const connector in synaps1[dispatchSynap]) {

  //           this._dispatchSynap(
  //             batch,
  //             dispatchSynap,
  //             synaps1[dispatchSynap][connector].synaps, synaps2,
  //             connector, key1,
  //             dispatch2.synaps[dispatchSynap].module,
  //             { synaps: { [synap]: { [key2]: alias } } },
  //             dispatch2.synaps[dispatchSynap],
  //             dispatch2.synaps[dispatchSynap],
  //             [...path, ...[{ synap: synap, connector: key2 }]],
  //             remove
  //           );
  //         }
  //       }
  //     }
  //   }
  // }


  // private _addDependantSynaps(alias: any, synaps: any, dispatch: Synap, path: { synap: string, connector: string }[]): any {

  //   if (synaps) {
  //     // Add all synaps then remove the non dependant ones
  //     const lastAlias = this._getLastAlias(alias, path);
  //     lastAlias.synaps = synaps;
  //     this._recursiveRemoveNonDependantSynap(lastAlias.synaps, dispatch ? dispatch : null)
  //     if (!Object.keys(lastAlias.synaps).length) {
  //       delete lastAlias.synaps;
  //     }
  //   }
  //   return alias;
  // }

  // private _getLastAlias(alias: any, path: { synap: string, connector: string }[]): any {
  //   let tmpAlias = alias;
  //   for (const step of path) {
  //     if (tmpAlias.synaps && tmpAlias.synaps[step.synap] && tmpAlias.synaps[step.synap][step.connector]) {
  //       tmpAlias = tmpAlias.synaps[step.synap][step.connector];
  //     }
  //   }
  //   return tmpAlias;
  // }

  // private _recursiveRemoveNonDependantSynap(aliasSynaps: any, dispatch: Synap) {
  //   if (aliasSynaps) {
  //     for (const synap in aliasSynaps) {
  //       if (dispatch && dispatch.synaps && dispatch.synaps[synap]) {
  //         for (const connector in aliasSynaps[synap]) {
  //           this._recursiveRemoveNonDependantSynap(aliasSynaps[synap][connector].synaps, dispatch.synaps[synap] ? dispatch.synaps[synap] : null)
  //         }
  //       } else {
  //         delete aliasSynaps[synap];
  //       }
  //     }
  //   }

  // }

  public createAlias(item: MainItem, synaps?: string[]): any {
    const alias = createAlias(item.values, item.module);
    if (synaps) {
      alias.synaps = {};
      // TODO check dependant...
      for (const synap of synaps) {
        if (item.values.synaps && item.values.synaps[synap]) {
          alias.synaps[synap] = item.values.synaps[synap];
        }
      }
    }
    return alias;
  }



  private _resetModify(fields: any, modify: any, specificFields: { [key: string]: boolean } = null) {
    for (const fieldKey in fields) {
      if (!specificFields || (specificFields && specificFields[fieldKey])) {
        if (fields[fieldKey].input == 'objectSub' || fields[fieldKey].input == 'objectSubType' || fields[fieldKey].input == 'dynSub') {
          this._resetModify(fields[fieldKey].sub, modify[fieldKey]);
        } else {
          modify[fieldKey] = false;
        }
      }
    }
  }


  deleteItem(item: MainItem): Promise<any> {
    if (this.canDelete) {
      const batch = firebase.firestore().batch();

      batch.delete(
        firebase.firestore().doc(`/db/modules/${this.moduleListName}/${item.values.$key}`)
      );

      if (this.listMode) {
        batch.delete(
          firebase.firestore().doc(`/list/modules/${this.moduleListName}/${item.values.$key}`)
        );
      }

      return batch.commit();
    }
  }

  deleteItemKey(itemKey: string): Promise<any> {
    if (this.canDelete) {
      const batch = firebase.firestore().batch();

      batch.delete(
        firebase.firestore().doc(`/db/modules/${this.moduleListName}/${itemKey}`)
      );

      // batch.delete(
      //   firebase.firestore().doc(`/list/modules/${this.moduleListName}/${itemKey}`)
      // );

      return batch.commit();
    }
  }

  //************** MANAGE ACCESS **********************/ 

  // protected get canRead(): boolean {
  //   return this.auth.loggedIn;
  // }

  // protected get canEdit(): boolean {
  //   return this.auth.loggedIn;
  // }

  // protected get canDelete(): boolean {
  //   return this.auth.loggedIn;
  // }


  //************** MANAGE DB **********************/ 

  protected _colWithIds(path, queryFn?): Observable<any[]> {
    return this.afs.collection(path, queryFn).snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            return a.payload.doc.data();
            // return new this.itemClass(a.payload.doc.data());
          });
        })
      );
  }


  protected _docWithIds(ref): Observable<any> {
    return this.afs.doc(ref).snapshotChanges().pipe(
      map(a => {
        if (a.payload.exists) {
          return new this.itemClass(a.payload.data());
        }
        return null;
      })
    );
  }

  //************** Manage Roles Access **********************/ 

  get canEdit(): boolean {
    if (this.auth.loggedIn) {
      if (this._editRoles.all || this._editRoles[this.auth.profile.values.role])
        return true;
    }
    return false;
  }

  get canAdd(): boolean {
    if (this.auth.loggedIn) {
      if (this._addRoles.all || this._addRoles[this.auth.profile.values.role])
        return true;
    }
    return false;
  }

  get canRead(): boolean {
    if (this.auth.loggedIn) {
      if (this._readRoles.all || this._readRoles[this.auth.profile.values.role]) {
        return true;
      }
    }
    return false;
  }

  get canDelete(): boolean {
    if (this.auth.loggedIn) {
      if (this._deleteRoles.all || this._deleteRoles[this.auth.profile.values.role]) {
        return true;
      }
    }
    return false;
  }

  isOneOfRoles(roles: string[]): boolean {
    return this.auth.isOneOfRoles(roles);
  }

  isRole(role: string): boolean {
    return this.auth.isRole(role);
  }

  selectRealtorFilter(): string | null {
    if (this.isOneOfRoles(['assistant', 'admin'])) {
      return '';
    }
    return this.auth.uid;
  }

  selectAgencyFilter(): string {
    if (this.auth.profile.values.role === 'realtor') {
      return null;
    }
    return this.auth.profile.values.agency ? this.auth.profile.values.agency : this.auth.profile.values.agencies ? this.auth.profile.values.agencies[0] : null;
  }

  //************** FILTERS **********************/ 

  // Function to distribute Search Bar between Local and Server Filters
  setSearchFilter(value: any) {
    if (value && value.length) {
      this._filters.search = value;
      if (isNaN(value)) {
        this._filters.local.localSearch = value.toLowerCase();
        this._removeRefFilter();
        this._localFilter();
      } else {
        if (!this._filters.server.filters) {
          this._filters.server.filters = {};
        }
        this._filters.server.filters.ref = parseInt(value);
        this.subscribe();
      }
    } else {
      this._filters.search = null;
      if (this._filters.local.localSearch.length) {
        this._filters.local.localSearch = '';
        this._localFilter();
      }
      this._removeRefFilter();
    }

  }

  protected _removeRefFilter() {
    if (this._filters.server.filters && this._filters.server.filters.ref) {
      delete this._filters.server.filters.ref;
      this.subscribe();
    }
  }

  get searchFilter() {
    return this._filters.search;
  }


  setFilters(value) {
    Object.assign(this._filters, value);
    if (value.server) {
      this.subscribe();
    } else if (value.local) {
      // this.LocalFilter();
    }
  }


  setServerFilter(value): Promise<any> {
    Object.assign(this._filters.server, value);
    // this.items$ = null;
    return this.subscribe();
  }

  get serverFilters() {
    return this._filters.server;
  }



  setLocalFilter(value) {
    Object.assign(this._filters.local, value);
    this._localFilter();
  }

  setLocalOrder(sort: { active: string, direction: 'asc' | 'desc' }) {
    this._filters.local.sort = sort;
    this._localOrder();
  }

  get localFilters() {
    return this._filters.local;
  }
  protected _localFilter() {
    if (this._filters.local == null) {
      this.itemsFiltered = this.items;
      return;
    }

    // Get BUDGET
    // let budget = null;
    // if (this._filters.local.budget && this._filters.local.filters.type != 'N' && this._filters.local.budget[this._filters.local.filters.type]) {
    //   budget = Object.assign({}, this._filters.local.budget[this._filters.local.filters.type]);
    //   budget.lower = budget.lower * this._filters.local.budget.unit[this._filters.local.filters.type];
    //   budget.upper = budget.upper * this._filters.local.budget.unit[this._filters.local.filters.type];
    //   budget.max = this._filters.local.budget.max[this._filters.local.filters.type] * this._filters.local.budget.unit[this._filters.local.filters.type];
    // }
    let localSearch: string[] = [];
    if (this._filters.local.localSearch) {
      localSearch = this._filters.local.localSearch.split('|');
    }

    // FILTER BAR ONLY
    this.itemsFiltered = this.items.filter((v) => {

      // filter map
      // if (this._filters.local.gmap) {
      //   if (r && this._filters.local.gmap.bounds && this._filters.local.gmap.zoom >= 15) {
      //     let gps = null;
      //     if (v.address && v.address.gps) {
      //       gps = v.address.gps;
      //     } else if (v.unit && v.unit.address && v.unit.address.gps) {
      //       gps = v.unit.address.gps;
      //     }
      //     if (gps && !this._filters.local.gmap.bounds.contains(new google.maps.LatLng(gps.latitude, gps.longitude))) {
      //       return false;
      //     }
      //   }
      // }

      if (this._stopFilter(v)) {
        return false;
      }


      // filter synaps
      if (this._filters.local.synaps) {
        for (let synap in this._filters.local.synaps) {
          if (this._filters.local.synaps[synap] != 'N' && (!v.synaps || !v.synaps[synap][this._filters.local.synaps[synap]])) {
            return false;
          }
        }
      }

      // Simple Filters
      if (this._filters.local.filters) {
        for (let filter in this._filters.local.filters) {
          if (this._filters.local.filters[filter] != 'N' && (!v[filter] || v[filter] !== this._filters.local.filters[filter])) {
            return false;
          }
        }
      }

      // Multi Filters
      if (this._filters.local.multiFilters) {
        for (let filter in this._filters.local.multiFilters) {
          if (this._filters.local.multiFilters[filter] && this._filters.local.multiFilters[filter].length) {
            if (!v[filter] || !this._filters.local.multiFilters[filter].includes(v[filter])) {
              return false;
            }
          }
        }
      }

      // Array Filters
      if (this._filters.local.arrayFilters) {
        for (let filter in this._filters.local.arrayFilters) {
          if (this._filters.local.arrayFilters[filter] && this._filters.local.arrayFilters[filter].length) {
            if (!v[filter] || !v[filter].includes(this._filters.local.arrayFilters[filter])) {
              return false;
            }
          }
        }
      }

      if (this._filters.local.equalSup) {
        for (let filter in this._filters.local.equalSup) {
          if (this._filters.local.equalSup[filter]) {
            if (!v[filter] || v[filter] < this._filters.local.equalSup[filter]) {
              return false;
            }
          }
        }
      }

      // Local Search
      if (localSearch.length) {
        const lowerV = JSON.stringify(v).toLowerCase();

        let match = false;
        for (const search of localSearch) {
          if (lowerV.includes(search)) {
            match = true;
          }
        }
        if (!match) {
          return false;
        }
      }

      return true;

    });

    this._localOrder();

    this.filterLoading = false;
    this._afterLocalFilter();
  }

  protected _afterLocalFilter() {

  }

  protected _localOrder() {
    // ORDER BY
    if (this._filters.local.sort) {
      const direction = this.localFilters.sort.direction === 'desc' ? -1 : 1;

      if (!this._localCustomOrder(direction)) {

        const fieldNumbers = ['ref', 'bedrooms', 'bathrooms', 'units', 'projects', 'surface', 'dateNeed', 'dateAvailable', 'minBedrooms', 'maxBudget', 'followUp', 'score'];

        if (fieldNumbers.includes(this._filters.local.sort.active)) {
          this._filterNumbers(direction);
        } else {
          this._filterStrings(direction);
        }

      } else {
        this.itemsFilteredOrdered = this.itemsFiltered;
      }
    } else {
      this.itemsFilteredOrdered = this.itemsFiltered;
    }
  }

  protected _filterNumbers(direction: number) {

    this.itemsFilteredOrdered = [...this.itemsFiltered.sort((a, b) => {
      let objectA: number = a[this._filters.local.sort.active] ? a[this._filters.local.sort.active] : 0;
      let objectB: number = b[this._filters.local.sort.active] ? b[this._filters.local.sort.active] : 0;

      return (objectA < objectB ? -1 : 1) * direction;
    })];
  }

  protected _filterStrings(direction: number) {

    this.itemsFilteredOrdered = [...this.itemsFiltered.sort((a, b) => {
      let objectA: number = a[this._filters.local.sort.active] ? a[this._filters.local.sort.active].toLowerCase() : '';
      let objectB: number = b[this._filters.local.sort.active] ? b[this._filters.local.sort.active].toLowerCase() : '';

      return (objectA < objectB ? -1 : 1) * direction;
    })];
  }

  protected _localCustomOrder(direction?: number): boolean {
    return false;
  }

  protected _stopFilter(v: any): boolean {
    return false;
  }


  //************** Record Config **********************/ 

  recordPageConfig(values: any, config: PageConfig) {
    this._itemPages[values.$key] = {
      values: values,
      pageConfig: config,
    };
  }

  get itemsPageConfig() {
    return this._itemsPage;
  }

  set itemsPageConfig(config: any) {
    this._itemsPage = config;
  }
}