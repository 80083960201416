import { Component, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MainComponent } from '../../common/pages/main/main.component';
import { Unit } from '../../services/unit/unit.model';
import { SettingService } from '../../common/services/setting/setting.service';
import { UnitService } from '../../services/unit/unit.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectService } from '../../common/services/select/select.service';
import { Mandate } from '../../services/mandate/mandate.model';
import { GpsComponent } from '../../common/components/gps/gps.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MandateService } from '../../services/mandate/mandate.service';
import { ActionService } from '../../services/action/action.service';
import { Action } from '../../services/action/action.model';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { SyncPortalService } from 'src/services/syncPortal/sync-portal.service';
import { TranslationService } from 'src/common/services/translation/translation.service';
import { OpenaiService } from 'src/common/services/openai/openai.service';
import { ActionSignMandate } from 'src/services/action/actions/action-sign-mandate.model';
import { Tools } from 'src/common/services/tools';

@Component({
  selector: 'app-unit',
  templateUrl: './unit.component.html',
  styleUrls: ['./unit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnitComponent extends MainComponent {

  public item: Unit;

  public tools = new Tools();
  // To Use in Units Table
  // public contactModel = new Contact();
  public mandateModel = new Mandate(null, this.setting);
  public mandateActive = false;
  public promoter = null;
  public chatGPTDisable = false;

  @ViewChild(GpsComponent) private gpsComponent: GpsComponent;

  constructor(
    protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: UnitService,
    public select: SelectService,
    protected route: ActivatedRoute,
    protected router: Router,
    public dialog: MatDialog,
    public backURL: BackurlService,
    public portalSyncs: SyncPortalService,
    public translation: TranslationService,
    public openai: OpenaiService,
  ) {
    super(_cd, setting, db, select, route, router, backURL, dialog);
    this.item = new Unit(null, setting);
  }



  _itemOnInit() {
    this.imageConf = { path: this.item.imageConfig.path, filename: this.item.imageConfig.filename(this.item.values) };

    if (this.item.values.synaps) {
      if (this.item.values.synaps.unitMandatesArray) {
        for (const mandate of this.item.values.synaps.unitMandatesArray) {
          if (mandate.status === 'active') {
            this.mandateActive = true;
          }
        }
      }
      if (this.item.values.synaps.residenceUnitsObj && this.item.values.synaps.residenceUnitsObj.synaps && this.item.values.synaps.residenceUnitsObj.synaps.promoter) {
        for (const companyKey in this.item.values.synaps.residenceUnitsObj.synaps.promoter) {
          this.promoter = this.item.values.synaps.residenceUnitsObj.synaps.promoter[companyKey];
          this.promoter.$key = companyKey;
        }
      }

    }

  }

  protected _afterBindUpdates() {
    if (this.item.values?.desc?.en?.length) {
      this.chatGPTDisable = false
    }
  }

  duplicate() {
    this.pageConfig.loaded = false;
    this.db.duplicateUnit(this.item).then((newUnit) => {
      this.backURL.addURL(`/units`);
      this.item = new Unit(null, this.setting);
      this._cd.markForCheck();
      // this.item = newUnit;
      this._$key = newUnit.values.$key;
      this.router.navigate([`/units/${newUnit.values.$key}`]);
      this._mainNgOnInit();
    });
  }

  // duplicate() {
  //   const data: any = { ok: false, vefa: false, mandate: false, price: 0, cy:'MUR' };
  //   const dialogRef = this.dialog.open(DialogDuplicateComponent, {
  //     width: '400px',
  //     disableClose: true,
  //     data: data
  //   });

  //   dialogRef.afterClosed().subscribe(() => {
  //     if (data.ok) {
  //       this.pageConfig.loaded = false;
  //       this.db.duplicateUnit(this.item).then((newKey) => {
  //         this.backURL.addURL(`/units/${this.item.values.$key}`);
  //         this.router.navigate([`/units/${newKey}`]); // Go to the new unit page;
  //         this._$key = newKey;
  //         this._mainNgOnInit();
  //       });
  //     }
  //   });
  // }

  addMandate() {
    const data: any = { ok: false, item: this.item, newMandateKey: null };
    const dialogRef = this.dialog.open(DialogAddMandateComponent, {
      width: '400px',
      disableClose: true,
      data: data
    });


    dialogRef.afterClosed().subscribe(() => {
      if (data.ok && data.newMandateKey) {
        this.backURL.addURL(`/units/${this.item.values.$key}`); // Go to the mandate page;
        this.router.navigate([`/mandates/${data.newMandateKey}`]);
      }
    });
  }


  // public marketingApprove() {
  //   if (this.checkMandatoryFields(this.item.values)) {
  //     this.item.values.marketingCheck = true;
  //     this.item.modify.marketingCheck = true;
  //     this.db.saveItem(this.item);
  //     // .then(() => {
  //     //   this.portalSyncs.approveAllPendingMandatesSync(this.item.values.$key);
  //     // });
  //   }
  // }

  // private _approveAllPendingMandatesSync() {

  // }

  // public checkMandatoryFieldsForGeneratingText(data: any): boolean {
  //   return (data.type && data.address && data.address.eCityName && (data.type !== 'house' && data.type !== 'apartment'  data.bedrooms && unit.bathrooms);

  // }

  onTabChanged(event: MatTabChangeEvent) {
  }


  public generateText() {
    this.chatGPTDisable = true;
    this.openai.advert(
      this.item.values,
      'a real estate title for the listing of my website',
      20,
      null,
      'units',
      'title',
      'en',
    );
    this.openai.advert(
      this.item.values,
      'a real estate advert for my website',
      200,
      'Nestenn offers',
      'units',
      'desc',
      'en',
    );
  }

  translate() {
    if (this.item.values.title || this.item.values.desc) {
      this.saveItem(false);
    }
    if (this.item.values.title) {
      this.translation.translate(
        'units',
        'title',
        this.item.values.$key,
        this.item.values.title,
      );
    }
    if (this.item.values.desc) {
      this.translation.translate(
        'units',
        'desc',
        this.item.values.$key,
        this.item.values.desc,
      );
    }
  }

  public copyGoogleMapURL(event) {
    event.stopPropagation();

    if (this.item.values.address && this.item.values.address.gps) {
      this.copyClipboard(this.tools.generateGoogleMapURL(this.item.values.address.gps));
    }
  }
}

@Component({
  selector: 'app-dialog-add-mandate',
  templateUrl: './add-mandate.component.html',
})
export class DialogAddMandateComponent {

  public item = new Mandate(null, this.setting);
  public actionItem = new Action(null, this.setting);
  private _realtor = '';


  constructor(
    protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: MandateService,
    public select: SelectService,
    protected route: ActivatedRoute,
    protected router: Router,
    public dialogRef: MatDialogRef<any>,
    public backURL: BackurlService,
    public dialog: MatDialog,
    public actionService: ActionService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    // super(_cd, setting, db, select, route, router, backURL, dialog);
    // this.item = new Mandate(null, setting);
    // this.actionService = new ActionService(this.db.auth, this.db.afs, this.setting);

    // Set Default Agent & Agency...
    this._realtor = this.db.auth.uid;
    this.item.values.realtor = this.db.auth.uid;
    this.item.values.agency = this.db.auth.profile.values.agency ? this.db.auth.profile.values.agency : this.db.auth.profile.values.agencies ? this.db.auth.profile.values.agencies[0] : null;
    this.setting.setDynAgencies(this.item.values.realtor);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async onYesClick(): Promise<void> {
    if (this.data.item.values.$key) {

      const vefa = !!this.data.item.values.vefa;

      // SETUP REALTOR
      // If VEFA get the realtor and agency of the residence otherwise use the user or the selected one
      if (vefa) {
        this.item.values.origin = 'vefa';
        this.item.values.type = 'sale';
      }
      if (vefa &&
        this.data.item.values.synaps &&
        this.data.item.values.synaps.residenceUnitsObj &&
        this.data.item.values.synaps.residenceUnitsObj.realtor &&
        this.data.item.values.synaps.residenceUnitsObj.agency) {
        this.item.values.realtor = this.data.item.values.synaps.residenceUnitsObj.realtor;
        this.item.values.agency = this.data.item.values.synaps.residenceUnitsObj.agency;
      } else {
        if (!this.item.values.realtor) {
          this.item.values.realtor = this.db.auth.uid;
        }
        if (!this.item.values.agency) {
          this.item.values.agency = this.setting.getDefaultAgency(this.item.values.realtor);
        }

      }

      // SETUP STATUS
      // Check if realtor doing the new project
      if (vefa || (this.item.values.realtor === this.db.auth.uid)) {
        this.item.values.status = "pending";
      } else {
        this.item.values.status = "new";
      }

      this.item.values.synaps = {
        unitMandates: {
          [this.data.item.values.$key]: this.data.item.alias
        }
      }
      await this.db.saveNewItem(this.item).then(async (newKey) => {


        await this.actionService.createAction( // Create New Mandate Action
          Action,
          'newMandate',
          [{ item: this.item }],
          {
            done: this.item.values.status === "pending",
            realtor: this.item.values.realtor,
            agency: this.item.values.agency,
            data: {
              data:
              {
                origin: vefa ? 'vefa' : this.item.values.origin,
                msg: this.item.values.originNote,
                referral: vefa ? null : this.item.values.referral,
              },
            },
          }
        );

        if (this.item.values.status === "pending") {
          await this.actionService.createAction( // Create Accept as it is the realtor
            Action,
            'accept',
            [{ item: this.item }],
            {
              realtor: this.item.values.realtor,
              agency: this.item.values.agency,
              done: true,
            }
          );


          if (this.item.values.type === 'rental' || vefa) { // Create the next action according to the type of mandate and if VEFA

            await this.actionService.createAction( // Create Sign Mandate for a  rental 
              ActionSignMandate,
              'signMandate',
              [{ item: this.item }],
              {
                realtor: this.item.values.realtor,
                agency: this.item.values.agency,
              }
            );
          } else {
            await this.actionService.createAction( // Create R1 for a sale
              Action,
              'r1',
              [{ item: this.item }],
              {
                realtor: this.item.values.realtor,
                agency: this.item.values.agency,
              }
            );
          }
        }
        this.data.ok = true;
        this.data.newMandateKey = newKey;
        this.dialogRef.close();
      });
    }
  }

  public touched() {
    // Check if realtor has changed, if Yes reset select agency...
    if (this._realtor !== this.item.values.realtor) {
      this.setting.setDynAgencies(this.item.values.realtor);
      this.setting.show = false;
      this.item.values.agency = null;
      this._realtor = this.item.values.realtor;
    }
  }


}


// @Component({
//   selector: 'app-dialog-duplicate',
//   templateUrl: './duplicate.component.html',
// })
// export class DialogDuplicateComponent {

//   public item = new Mandate(null, this.setting);
//   public actionItem = new Action(null, this.setting);


//   constructor(
//     public setting: SettingService,
//     public dialogRef: MatDialogRef<DialogDuplicateComponent>,
//     @Inject(MAT_DIALOG_DATA) public data: any) {
//   }

//   onNoClick(): void {
//     this.dialogRef.close();
//   }

//   async onYesClick(): Promise<void> {
//   }


// }