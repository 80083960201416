import { Component } from '@angular/core';
import { MainsComponent } from '../../common/pages/mains/mains.component';
import { SettingService } from '../../common/services/setting/setting.service';
import { SelectService } from '../../common/services/select/select.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { ProjectService } from '../../services/project/project.service';
import { BackurlService } from 'src/common/services/backurl/backurl.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent extends MainsComponent {

  constructor(
    public setting: SettingService,
    public db: ProjectService,
    public select: SelectService,
    protected router: Router,
    protected fb: FormBuilder,
    public backURL: BackurlService,
  ) {
    super(setting, db, select, router, fb, backURL);

    this.sortServerForm = this.fb.group({
      agency: [],
      realtor: [],
      filters: this.fb.group({
        referral: [],
        referralAgency: [],
      }),
      multiFilters: this.fb.group({
        status: [],
      }),
    });

    this.sortLocalForm = this.fb.group({
      localSearch: [''],
      filters: this.fb.group({
        type: ['N'],
        status: ['N'],
        temperature: ['N'],
      }),
      arrayFilters: this.fb.group({
        unitTypes: [''],
      }),
    });
  }


  filterStatus(status: string, temperature: string = 'N') {
    const multiFiltersStatus = this.sortServerForm.get('multiFilters.status');
    const localFiltersStatus = this.sortLocalForm.get('filters.status');
    const localFiltersTemperature = this.sortLocalForm.get('filters.temperature');

    if (status === 'N' && (multiFiltersStatus.value.includes('deal') || multiFiltersStatus.value.includes('failed'))) {
      multiFiltersStatus.setValue(this.db.status);
      localFiltersStatus.setValue('N');
      localFiltersTemperature.setValue(temperature);
    } else if (status === 'deal' || status === 'failed') {
      multiFiltersStatus.setValue([status]);
      localFiltersStatus.setValue('N');
      localFiltersTemperature.setValue(temperature);
    } else {
      localFiltersStatus.setValue(status);
      localFiltersTemperature.setValue(temperature);
    }
  }

}