import { _getFirstPhoto } from "./list";


export default function createAlias(data: any, module: string, synaps: string[] | null = null): any {


    switch (module) {
        case 'actions': {
            return actions(data, synaps);
        }
        case 'companies': {
            return companies(data, synaps);
        }
        case 'contacts': {
            return contacts(data, synaps);
        }
        case 'files': {
            return files(data, synaps);
        }
        case 'mandates': {
            return mandates(data, synaps);
        }
        case 'matchings': {
            return matchings(data, synaps);
        }
        case 'projects': {
            return projects(data, synaps);
        }
        case 'residences': {
            return residences(data, synaps);
        }
        case 'units': {
            return units(data, synaps);
        }
        default: {
            return {};
        }
    }
}

function addSynaps(data: any, alias: any, synaps: string[] | null) {
    if (alias && synaps) {
        alias.synaps = {};
        for (const synap of synaps) {
            if (data.synaps && data.synaps[synap]) {
                alias.synaps[synap] = data.synaps[synap];
            }
        }
    }
    return alias;
}

export function isAliasModified(beforeData: any, newData: any, module: string): boolean {

    if (!(beforeData.updated && newData.updated) || newData.updated.isEqual(beforeData.updated)) {
        return false;
    }

    switch (module) {
        case 'actions': {
            return actionsModified(newData);
        }
        case 'companies': {
            return companiesModified(newData);
        }
        case 'contacts': {
            return contactsModified(newData);
        }
        case 'files': {
            return filesModified();
        }
        case 'mandates': {
            return mandatesModified(newData);
        }
        case 'matchings': {
            return matchingsModified(newData);
        }
        case 'projects': {
            return projectsModified(newData);
        }
        case 'residences': {
            return residencesModified(newData);
        }
        case 'units': {
            return unitsModified(newData);
        }
        default: {
            // console.log(`Alias check: no module ${module}`);
            return false;
        }
    }
}


function actions(action: any, synaps: string[] | null): any {
    if (action) {
        return addSynaps(action, {
            done: !!action.done,
            date: _checkUndefined(action.date),
            type: _checkUndefined(action.type),
            realtor: _checkUndefined(action.realtor),
            agency: _checkUndefined(action.agency),
        },
            synaps);
    }
    return null;
}
function actionsModified(action: any): boolean {
    const fields = ['done', 'date', 'type', 'realtor', 'agency'];
    return checkIfChangedField(action, action.updatedFields, action.updated, fields);
}

function contacts(contact: any, synaps: string[] | null): any {
    return addSynaps(contact, {
        ref: _checkUndefined(contact.ref),
        fullname: _checkUndefined(contact.fullname),
        nationalities: contact.nationalities ? contact.nationalities : null,
        lang: _checkUndefined(contact.lang),
    },
        synaps);
}
function contactsModified(contact: any): boolean {
    const fields = ['ref', 'title', 'firstname', 'lastname', 'lang'];
    return checkIfChangedField(contact, contact.updatedFields, contact.updated, fields);
}

function companies(company: any, synaps: string[] | null): any {
    return addSynaps(company, {
        ref: _checkUndefined(company.ref),
        displayname: _checkUndefined(company.displayname),
    },
        synaps);
}
function companiesModified(company: any): boolean {
    const fields = ['ref', 'name', 'tradingAs'];
    return checkIfChangedField(company, company.updatedFields, company.updated, fields);
}


function files(file: any, synaps: string[] | null): any {
    return addSynaps(file, {
        name: _checkUndefined(file.name),   // name: string;
        path: _checkUndefined(file.path),   // path: string;
        type: _checkUndefined(file.type),   // type: string;
        url: _checkUndefined(file.url),   // url: string;
    }
        , synaps);
}

function filesModified(): boolean {
    return true;
}

function mandates(mandate: any, synaps: string[] | null): any {
    return addSynaps(mandate, {
        ref: _checkUndefined(mandate.ref),
        type: _checkUndefined(mandate.type),
        status: _checkUndefined(mandate.status),
        ad: !!mandate.ad,
        temperature: _checkUndefined(mandate.temperature),
        realtor: _checkUndefined(mandate.realtor),
        agency: _checkUndefined(mandate.agency),
        dateAvailable: _checkUndefined(mandate.dateAvailable),
        exclusive: _checkUndefined(mandate.exclusive),
        price: _checkUndefined(mandate.price),
        priceNeg: _checkUndefined(mandate.priceNeg),
        cy: _checkUndefined(mandate.cy),
    },
        synaps);
}
function mandatesModified(mandate: any): boolean {
    const fields = ['ref', 'type', 'status', 'ad', 'temperature', 'realtor', 'agency', 'dateAvailable', 'exclusive', 'price', 'priceNeg', 'cy'];
    return checkIfChangedField(mandate, mandate.updatedFields, mandate.updated, fields);
}

function matchings(matching: any, synaps: string[] | null): any {
    let mandateKey: string | null = null;
    let projectKey: string | null = null;
    let mandateActive: boolean = false;
    let projectActive: boolean = false;


    if (matching) {
        if (matching.synaps) {
            if (matching.synaps.mandateMatchings) {
                for (const mKey in matching.synaps.mandateMatchings) {
                    mandateKey = mKey;
                    mandateActive = (matching.synaps.mandateMatchings[mKey].status === 'active')
                }
            }
            if (matching.synaps.projectMatchings) {
                for (const pKey in matching.synaps.projectMatchings) {
                    projectKey = pKey;
                    projectActive = (matching.synaps.projectMatchings[pKey].status === 'active')
                }
            }
        }
        return {
            active: (!matching.new && matching.match && mandateActive && projectActive),
            match: !!matching.match,
            new: !!matching.new,
            mandateKey: mandateKey,
            projectKey: projectKey,
        };
    }
    return null;
}

function matchingsModified(matching: any): boolean {
    const fields = ['match', 'new'];
    return checkIfChangedField(matching, matching.updatedFields, matching.updated, fields);
}

function projects(project: any, synaps: string[] | null): any {
    return addSynaps(project, {
        ref: _checkUndefined(project.ref),
        type: _checkUndefined(project.type),
        status: _checkUndefined(project.status),
        temperature: _checkUndefined(project.temperature),
        realtor: _checkUndefined(project.realtor),
        agency: _checkUndefined(project.agency),
        dateNeed: _checkUndefined(project.dateNeed),
        unitTypes: _checkUndefined(project.unitTypes),
        minBedrooms: _checkUndefined(project.minBedrooms),
        cyBudget: _checkUndefined(project.cyBudget),
        minBudget: _checkUndefined(project.minBudget),
        maxBudget: _checkUndefined(project.maxBudget),
        foreigner: !!project.foreigner,
    },
        synaps);
}
function projectsModified(project: any): boolean {
    const fields = ['ref', 'type', 'status', 'temperature', 'realtor', 'agency', 'dateNeed', 'unitTypes', 'minBedrooms', 'cyBudget', 'minBudget', 'maxBudget', 'foreigner'];
    return checkIfChangedField(project, project.updatedFields, project.updated, fields);
}

function residences(residence: any, synaps: string[] | null): any {
    let nbErrors = 0;
    let nbAlerts = 0;
    if (residence.alerts && residence.alerts.length) {
        for (const alert of residence.alerts) {
            if (alert.type === 'error') {
                nbErrors++;
            } else {
                nbAlerts++;
            }
        }
    }

    return addSynaps(residence, {
        ref: _checkUndefined(residence.ref),
        alerts: nbAlerts,
        errors: nbErrors,
        name: _checkUndefined(residence.name),
        types: _checkUndefined(residence.types),
        scheme: _checkUndefined(residence.scheme),
        topFloor: _checkUndefined(residence.topFloor),
        landType: _checkUndefined(residence.landType),
        address: _checkUndefined(residence.address),
        builtYear: _checkUndefined(residence.builtYear),
        totalUnits: _checkUndefined(residence.totalUnits),
        params: _checkUndefined(residence.params),
        equipment: _checkUndefined(residence.equipment),
        appliances: _checkUndefined(residence.appliances),
        security: _checkUndefined(residence.security),
        area: _checkUndefined(residence.area),
        realtor: _checkUndefined(residence.realtor),
        agency: _checkUndefined(residence.agency),
    },
        synaps);
}
function residencesModified(residence: any): boolean {
    const fields = ['name', 'types', 'scheme', 'landType', 'builtYear', 'topFloor', 'totalUnits', 'realtor', 'agency',
        {
            params: ['commonPool', 'commonGym', 'commonTennis', 'commonSpa', 'golf', 'marina'],
            equipment: ['commonGenerator', 'lift'],
            appliances: ['opticalFiber'],
            security: ['watchman', 'securityGuard', 'secureParking'],
            area: ['beachfront', 'seaAccess', 'nearSchool', 'nearBus', 'nearGolf', 'nearHospital', 'nearShops'],
            address: ['eCity', 'eCityName', 'street', 'city', 'postcode', 'district', 'country', 'gps', 'eGps', 'sectors', 'note']
        }
    ];
    return checkIfChangedField(residence, residence.updatedFields, residence.updated, fields);
}

function units(unit: any, synaps: string[] | null): any {
    let nbErrors = 0;
    let nbAlerts = 0;
    if (unit.alerts && unit.alerts.length) {
        for (const alert of unit.alerts) {
            if (alert.type === 'error') {
                nbErrors++;
            } else {
                nbAlerts++;
            }
        }
    }

    return addSynaps(unit, {
        ref: _checkUndefined(unit.ref),
        alerts: nbAlerts,
        errors: nbErrors,
        vefa: !!unit.vefa,
        pm: !!unit.pm,
        vefaName: _checkUndefined(unit.vefaName),
        vefaNb: _checkUndefined(unit.vefaNb),
        vefaAv: _checkUndefined(unit.vefaAv),
        type: _checkUndefined(unit.type),
        typeDisp: _checkUndefined(unit.typeDisp),
        condition: _checkUndefined(unit.condition),
        landType: _checkUndefined(unit.landType),
        leasehold: _checkUndefined(unit.leasehold),
        penthouse: !!unit.penthouse,
        bedrooms: _checkUndefined(unit.bedrooms),
        bathrooms: _checkUndefined(unit.bathrooms),
        toilets: _checkUndefined(unit.toilets),
        builtSurface: _checkUndefined(unit.builtSurface),
        livingSurface: _checkUndefined(unit.livingSurface),
        landSurface: _checkUndefined(unit.landSurface),
        landUnit: _checkUndefined(unit.landUnit),
        photo: _getFirstPhoto(unit.synaps?.filesUnits),

        includeAC: !!unit.includeAC,
        garden: _checkUndefined(unit.garden),
        address: _checkUndefined(unit.address),
        style: _checkUndefined(unit.style),
        floor: _checkUndefined(unit.floor),
        topFloor: _checkUndefined(unit.topFloor),
        storeys: _checkUndefined(unit.storeys),
        kitchenType: _checkUndefined(unit.kitchenType),
        score: _checkUndefined(unit.score),
        // marketingCheck: !!unit.marketingCheck,
        params: {
            privatePool: !!(unit.params && unit.params.privatePool),
            office: !!(unit.params && unit.params.office),
        },
        equipment: {
            furnished: !!(unit.equipment && unit.equipment.furnished),
            semifurnished: !!(unit.equipment && unit.equipment.semifurnished),
            unfurnished: !!(unit.equipment && unit.equipment.unfurnished),
            petAllowed: !!(unit.equipment && unit.equipment.petAllowed),
            generator: !!(unit.equipment && unit.equipment.generator),
        },
        appliances: {
            opticalFiber: !!(unit.appliances && unit.appliances.opticalFiber),
            wifi: !!(unit.appliances && unit.appliances.wifi),
        },
        security: {
            alarm: !!(unit.security && unit.security.alarm),
            watchman: !!(unit.security && unit.security.watchman),
            securityGuard: !!(unit.security && unit.security.securityGuard),
        },
        view: {
            seaView: !!(unit.view && unit.view.seaView),
        },
        area: {
            beachfront: !!(unit.area && unit.area.beachfront),
            seaAccess: !!(unit.area && unit.area.seaAccess),
        },
    },
        synaps);
}
function unitsModified(unit: any): boolean {
    const fields = ['alerts', 'errors', 'ref', 'vefa', 'vefaName', 'vefaNb', 'vefaAv', 'pm', 'type', 'typeDisp', 'landType'
        , 'leasehold', 'penthouse', 'bedrooms', 'bathrooms', 'toilets', 'builtSurface', 'livingSurface', 'landSurface', 'landUnit', 'photos'
        , 'garden', 'style', 'floor', 'storeys', 'kitchenType', 'topFloor', 'score',
        {
            address: ['eCity', 'eCityName', 'unitNb', 'street', 'city', 'postcode', 'district', 'country', 'gps', 'eGps', 'sectors', 'note'],
            params: ['privatePool', 'office'],
            equipment: ['furnished', 'semifurnished', 'unfurnished', 'petAllowed', 'generator'],
            appliances: ['opticalFiber', 'wifi'],
            security: ['alarm', 'watchman', 'securityGuard'],
            view: ['seaView'],
            area: ['beachfront', 'seaAccess']
        }];

    return checkIfChangedField(unit, unit.updatedFields, unit.updated, fields);
}

function _checkUndefined(value: any): any {
    return value === undefined ? null : value;
}

// Return True if one of the fields changed
export function checkIfChangedField(data: any, updatedFields: any, updated: any, fields: any[]): boolean {
    if (data && updated && updatedFields) {
        for (const field of fields) {
            if (typeof field === 'object') {
                for (const fieldObj in field) {
                    if (checkIfChangedField(data[fieldObj], updatedFields[fieldObj], updated, field[fieldObj])) {
                        return true;
                    }
                }
            } else if (updatedFields[field] && updated.isEqual(updatedFields[field])) {
                return true;
            }
        }
    }
    return false;
}

