import * as firebase from 'firebase/app';
import * as moment from 'moment';

export class Tools {

    // return number of days from now
    public days(date: firebase.firestore.Timestamp): number {
        if (date) {
            return Math.round((date.seconds - firebase.firestore.Timestamp.now().seconds) / 86400);
        }
        return null;
    }

    public timeago(timestamp: firebase.firestore.Timestamp | Date | moment.Moment): string {
        let ago = '';
        let past = true;
        if (timestamp) {
            let agoMin = 0;
            if (timestamp instanceof Date) {
                agoMin = Math.floor(
                    (firebase.firestore.Timestamp.now().seconds - timestamp.getTime() / 1000) / 60
                );
            } else if (moment.isMoment(timestamp)) {
                agoMin = Math.floor(
                    (firebase.firestore.Timestamp.now().seconds - timestamp.valueOf() / 1000) / 60
                );
            } else {
                agoMin = Math.floor(
                    (firebase.firestore.Timestamp.now().seconds - timestamp.seconds) / 60
                );
            }
            if (agoMin < 0) {
                past = false;
                agoMin = -agoMin;
            }

            let agoDays = 0;
            if (agoMin >= 1440) {
                agoDays = Math.floor(agoMin / 1440);
                ago += agoDays + ' day' + (agoDays ? 's ' : ' ');
                agoMin = agoDays * 1440;
            }

            if (!agoDays) {
                let agoHours = 0;
                if (agoMin >= 60) {
                    agoHours = Math.floor(agoMin / 60);
                    ago += agoHours + ' hour' + (agoHours ? 's ' : ' ');
                    agoMin -= agoHours * 60;
                }

                if (agoHours < 6) {
                    ago += agoMin + ' min' + (agoMin ? 's ' : ' ');
                }
            }
            if (past) {
                return ago + ' ago';
            }
            return 'in ' + ago;
        }
        return 'No Time';
    }

    public getMTime(time: Date | moment.Moment): number | null {
        if (time instanceof Date) {
            return time.getTime(); // For JavaScript Date objects
        } else if (moment.isMoment(time)) {
            return time.valueOf(); // For Moment.js objects
        } else {
            return null; // Handle other cases or unsupported types
        }
    }

    public ifPast(timestamp: firebase.firestore.Timestamp | Date): boolean {
        if (!timestamp) {
            return false;
        }

        const nowInSeconds = firebase.firestore.Timestamp.now().seconds;
        const timestampInSeconds = timestamp instanceof Date ? timestamp.getTime() / 1000 : timestamp.seconds;

        return (nowInSeconds - timestampInSeconds) / 86400 > 0;

    }

    public ifFuture(timestamp: firebase.firestore.Timestamp | Date): boolean {
        if (!timestamp) {
            return false;
        }

        const nowInSeconds = firebase.firestore.Timestamp.now().seconds;
        const timestampInSeconds = timestamp instanceof Date ? timestamp.getTime() / 1000 : timestamp.seconds;

        return (timestampInSeconds - nowInSeconds) / 86400 > 0;
    }
    Exp

    public warnPastColor(timestamp: firebase.firestore.Timestamp | Date): string {
        let days = 0;
        if (timestamp instanceof Date) {
            days = Math.floor(
                (firebase.firestore.Timestamp.now().seconds - timestamp.getTime() / 1000) / 86400
            );
        } else {
            days = Math.floor(
                (firebase.firestore.Timestamp.now().seconds - timestamp.seconds) / 86400
            );
        }
        if (days < 0) {
            return 'active';
        }
        return 'warm';
    }

    public daysColor(days: number): string {
        if (days < 0) {
            return 'hot';
        } else if (days < 15) {
            return 'warm';
        } else {
            return 'active';
        }
    }

    public scoreColor(score: number): string {
        if (score < 60) {
            return 'hot';
        } else if (score < 70) {
            return 'warm';
        } else {
            return 'active';
        }
    }

    public convertToJavaDate(input): Date {
        if (moment.isMoment(input)) {
            // If input is a Moment object, convert it to a JavaScript Date object
            return input.toDate();
        } else if (input instanceof Date) {
            // If input is already a JavaScript Date object, return it as is
            return input;
        } else {
            // If input is neither a Moment object nor a JavaScript Date object, return null or handle the error accordingly
            return null;
        }
    }

    public generateGoogleMapURL(geoPoint: firebase.firestore.GeoPoint): string {
        const latitude = geoPoint.latitude;
        const longitude = geoPoint.longitude;
        return `https://www.google.com/maps?q=${latitude},${longitude}`;
    }

}