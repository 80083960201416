<mat-toolbar class="projectsbg">
    <div>
        <button mat-icon-button (click)="goBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <div class="title">
            <mat-icon [ngClass]="item.values.status === 'active' ? item.values.temperature : item.values.status">
                {{item.values.status | icon:'projectStatus'}}</mat-icon>
            <ng-container>
                C{{item.values.ref}}:
                {{item.values.type | param:'projectTypes'}} -
                {{(item.values.synaps?.contactsProjectsArray && item.values.synaps.contactsProjectsArray[0]) ?
                item.values.synaps.contactsProjectsArray[0].fullname : 'Missing Name'}}
            </ng-container>
        </div>
    </div>
    <div>
        <profile></profile>
        <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <ng-container
                *ngIf="isOneOfRoles(['admin', 'director', 'assistant' ]) || (isOneOfRoles(['realtor']) && item.values.realtor === db.auth.uid)">
                <button mat-menu-item *ngIf="item.values.status !== 'deal' && item.values.status !== 'failed'"
                    (click)="handover()">
                    <mat-icon>{{GLOBAL.ICONS.HANDOVER}}</mat-icon>
                    <span>Swap Agent</span>
                </button>
                <button *ngIf="item.values.status === 'active'" mat-menu-item (click)="pause(true)">
                    <mat-icon>{{GLOBAL.ICONS.PAUSE}}</mat-icon>
                    <span>Pause</span>
                </button>
                <button *ngIf="item.values.status === 'pause'" mat-menu-item (click)="pause(false)">
                    <mat-icon class="active">{{GLOBAL.ICONS.ACTIVE}}</mat-icon>
                    <span>Active</span>
                </button>
                <button *ngIf="item.values.status !== 'failed' && item.values.status !== 'deal'" mat-menu-item
                    (click)="failed()">
                    <mat-icon>{{GLOBAL.ICONS.FAILED}}</mat-icon>
                    <span>Failed</span>
                </button>
                <button *ngIf="item.values.status === 'failed' && isOneOfRoles(['admin', 'assistant', 'director'])"
                    mat-menu-item (click)="pause(false)">
                    <mat-icon class="active">{{GLOBAL.ICONS.ACTIVE}}</mat-icon>
                    <span>Active</span>
                </button>
            </ng-container>
            <button mat-menu-item (click)="logs()">
                <mat-icon>{{GLOBAL.ICONS.LOGS}}</mat-icon>
                <span>Logs</span>
            </button>
        </mat-menu>
    </div>

</mat-toolbar>
<div class="toolbartop"></div>

<div class="mainPage">
    <img mat-card-avatar class="realtorPage" [src]="item.values.realtor | realtor"
        [matTooltip]="(item.values.realtor | realtorName) + '&#13;' + (item.values.agency | param:'agencies')"
        *ngIf="db.auth.uid !== item.values.realtor" />

    <mat-progress-bar mode="indeterminate" *ngIf="item.values.matching"></mat-progress-bar>
    <mat-tab-group [(selectedIndex)]="pageConfig.mainTab" (selectedTabChange)="tabChanged()">

        <!--------------------------------------------- OVERVIEW ----------------------------------------------------------->

        <mat-tab *ngIf="item.values.status !== 'new' && item.values.status !== 'discovery'">
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.DASHBOARD}}</mat-icon>&nbsp;
                Overview
            </ng-template>

            <div class="mdc-layout-grid">
                <div class="mdc-layout-grid__inner">
                    <div class="mdc-layout-grid__cell--span-3">
                        <mat-card class="backspace">
                            <mat-list>
                                <mat-list-item>
                                    <div matLine style="font-size: 35px;text-align: center;"
                                        [ngClass]="colorProjectDate()">
                                        {{tools.timeago(item.values.dateNeed)}}</div>

                                </mat-list-item>
                            </mat-list>
                        </mat-card>
                        <app-project-edit [item]="item" [bckpValues]="bckpValues"></app-project-edit>
                        <app-agency-fee-project-edit [item]="item" [bckpValues]="bckpValues"
                            *ngIf="['payment', 'leaseAgree', 'signAgree', 'signDeed', 'deal'].includes(item.values.status)">
                        </app-agency-fee-project-edit>
                    </div>
                    <div class="mdc-layout-grid__cell--span-6">
                        <div class="mdc-layout-grid__inner">
                            <div class="mdc-layout-grid__cell--span-6">
                                <mat-card class="backspace cursor" (click)="moveTab(1)">
                                    <!-- ACTIONS -->
                                    <mat-list>
                                        <mat-list-item *ngFor="let action of pendingActions">
                                            <mat-icon matListIcon color="accent">{{action.icon}}</mat-icon>
                                            <div matLine>
                                                <b>{{action.type | param:'actionTypes'}}</b>
                                            </div>
                                            <div matLine>
                                                <i *ngIf="action.date">
                                                    {{action.date.toDate() | date:'dd MMM yyyy HH:mm'}}</i>
                                            </div>
                                        </mat-list-item>
                                        <ng-container *ngFor="let action of dashDisplayActions">
                                            <mat-list-item *ngIf="dashActions[action]">
                                                <mat-icon matListIcon
                                                    [color]="dashActions[action].nb ? 'primary' : ''">{{dashActions[action].icon}}</mat-icon>
                                                <div matLine>
                                                    <b>{{dashActions[action].nb}} {{dashActions[action].label}}</b>
                                                </div>
                                                <div matLine>
                                                    <i *ngIf="dashActions[action].timeago.length">
                                                        {{dashActions[action].timeago}}</i>
                                                </div>
                                            </mat-list-item>
                                        </ng-container>
                                    </mat-list>
                                </mat-card>
                            </div>
                            <div class="mdc-layout-grid__cell--span-6">
                                <!-- Display Stats -->
                                <mat-card class="backspace ">
                                    <mat-list>
                                        <mat-list-item *ngIf="matchNb.new" class="cursor" (click)="moveTab(3)">
                                            <mat-icon matListIcon color="warn">{{GLOBAL.ICONS.NEW}}</mat-icon>
                                            <div matLine style="font-size: 25px;text-align: center;">
                                                {{matchNb.new}} New Matchings</div>
                                        </mat-list-item>
                                        <mat-list-item class="cursor" (click)="moveTab(2)">
                                            <mat-icon matListIcon>{{GLOBAL.ICONS.MATCHING}}</mat-icon>
                                            <div matLine style="font-size: 25px;text-align: center;">
                                                {{matchNb.match}}/{{matchNb.total}} Matchings</div>
                                        </mat-list-item>
                                    </mat-list>
                                    <mat-list *ngIf="item.values.matching" style="position:relative">
                                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                                    </mat-list>
                                </mat-card>
                            </div>
                        </div>
                    </div>
                    <div class="mdc-layout-grid__cell--span-3">
                        <mat-tab-group *ngIf="item.values.synaps?.contactsProjectsArray">
                            <mat-tab *ngFor="let contact of item.values.synaps.contactsProjectsArray">
                                <ng-template mat-tab-label>
                                    <mat-icon
                                        class="example-tab-icon">{{GLOBAL.ICONS.CONTACT}}</mat-icon>{{contact.fullname}}
                                </ng-template>
                                <app-contact-tab [key]="contact.$key" model="projects" [modelKey]="item.values.$key"
                                    [displayTelMail]="item.values.status !== 'new'">
                                </app-contact-tab>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </div>
            </div>

            <button mat-fab class="bottom-right pfixed p2" color="basic"
                (click)="select.init(item, db, 'contactsProjects')">
                <mat-icon matListIcon>sync_alt</mat-icon>
            </button>
        </mat-tab>


        <!--------------------------------------------- TIMELINE ----------------------------------------------------------->
        <mat-tab [disabled]="matchSelect">
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.ACTIONS}}</mat-icon>&nbsp;
                Tasks
            </ng-template>

            <!-- ACTIONS -->
            <div class="container split s25">
                <div class="main">
                    <div class="action" *ngFor="let action of actionService?.items">

                        <div class="date">
                            <div class="txt">
                                {{action.date?.toDate() | date:'dd MMM yy'}}<br />
                                {{action.date?.toDate() | date:'HH:mm'}}
                            </div>
                            <div class="actionRealtor" (click)="copyClipboard(action.$key)">
                                <button mat-mini-fab [color]="action.done ? 'primary' : 'accent'" style="cursor:none">
                                    <mat-icon>{{action.type | icon:'actionTypes'}}</mat-icon>
                                </button>
                                <img mat-card-avatar [src]="action.realtor | realtor"
                                    [matTooltip]="action.realtor | realtorName" />
                            </div>
                        </div>
                        <div class="panel">
                            <mat-spinner [strokeWidth]="3" [diameter]="70" *ngIf="action.creating"></mat-spinner>
                            <action-select *ngIf="!action.creating" [action]="action" [moduleItem]="item"
                                [allMatchings]="matching.items" [newAction]="newAction"
                                (selectDoneFn)="actionDone($event)" (selectAddFn)="addMatching($event)"></action-select>
                        </div>
                    </div>
                </div>
                <!-- OVERVIEW -->
                <div class="side">
                    <mat-tab-group *ngIf="item.values.synaps?.contactsProjectsArray">
                        <mat-tab *ngFor="let contact of item.values.synaps.contactsProjectsArray">
                            <ng-template mat-tab-label>
                                <mat-icon
                                    class="example-tab-icon">{{GLOBAL.ICONS.CONTACT}}</mat-icon>{{contact.fullname}}
                            </ng-template>
                            <app-contact-tab [key]="contact.$key" model="projects" [modelKey]="item.values.$key"
                                [displayTelMail]="item.values.status !== 'new'">
                            </app-contact-tab>
                        </mat-tab>
                    </mat-tab-group>

                </div>
            </div>

            <button *ngIf="item.values.status === 'new'" mat-fab color="primary" class="bottom-right pfixed"
                (click)="acceptProject()">
                <mat-icon matListIcon>thumb_up</mat-icon>
            </button>

            <app-speed-dial-fab *ngIf="item.values.status !== 'new'" [fabButtons]="actionButtons"
                (clickFn)="createActionMenu($event)">
            </app-speed-dial-fab>
        </mat-tab>





        <!----------------------------------- PROJECT CRITERIAS ----------------------------------------------->

        <mat-tab [disabled]="item.values.status === 'new' || item.values.status === 'discovery' || matchSelect">
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.DETAILS}}</mat-icon>&nbsp;
                Criteria
            </ng-template>
            <div class="mdc-layout-grid">
                <div class="mdc-layout-grid__inner">
                    <div class="mdc-layout-grid__cell--span-4">
                        <form-builder [item]="item" [values]="item.values" section="unit" [edit]="pageConfig.edit">
                        </form-builder>
                    </div>
                    <div class="mdc-layout-grid__cell--span-4">
                        <form-builder class="backspace" style="display: block;" [item]="item" [values]="item.values"
                            section="criteria" [edit]="pageConfig.edit">
                        </form-builder>
                        <app-matching-report [matchingResults]="item.values.matchingResults"></app-matching-report>
                    </div>
                    <div class="mdc-layout-grid__cell--span-4">
                        <sectors-select [edit]="pageConfig.edit" [value]="item.values.sectors"
                            (modify)="sectorsModified($event)" style="min-height: 250px; height: 100%;">
                        </sectors-select>
                    </div>
                </div>
            </div>


            <!-- BUTTONS -->
            <button
                *ngIf="!pageConfig.edit && (item.values.status === 'active' || item.values.status === 'pause') && 
            (isOneOfRoles(['admin', 'director', 'assistant']) || (isRole('realtor') && item.values.realtor === db.auth.uid))"
                mat-fab class="bottom-right pfixed" (click)="editItem()">
                <mat-icon matListIcon>edit</mat-icon>
            </button>
            <button *ngIf="pageConfig.edit" mat-fab class="bottom-right pfixed"
                [disabled]="!item.valid || !item.modified || item.saving || item.values.matching"
                (click)="saveItemAndClose()">
                <mat-icon matListIcon>check</mat-icon>
            </button>
            <button *ngIf="pageConfig.edit" mat-fab color="basic" class="bottom-right pfixed p2"
                (click)="cancelProjectEdit()">
                <mat-icon matListIcon>close</mat-icon>
            </button>
        </mat-tab>



        <!--------------------------------- MATCHING DETAILS ------------------------------------------------------>

        <mat-tab [disabled]="item.values.status === 'new' || item.values.status === 'discovery'">
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.MATCHING}}</mat-icon>&nbsp;
                {{matchNb.match}}/{{matchNb.total}} Matchings
            </ng-template>
            <mandateMatchings *ngIf="matching" [matching]="matching" [matchOnly]="matchOnly" [matchSelect]="matchSelect"
                [status]="item.values.status" [project]="this.item" (doneFn)="saveVisitMatching()"
                (cancelFn)="cancelVisitMatching()">
            </mandateMatchings>

            <button *ngIf="!matchSelect && item.values.status === 'active'" mat-fab color="basic"
                class="bottom-right pfixed" (click)="addMatchingMandate()">
                <mat-icon matListIcon>add</mat-icon>
            </button>
        </mat-tab>

        <!--------------------------------- NEW MATCHINGS ------------------------------------------------------>

        <mat-tab *ngIf="matchNb.new" [disabled]="matchSelect">
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon" color="warn">{{GLOBAL.ICONS.NEW}}</mat-icon>&nbsp;
                {{matchNb.new}} New Matchings
            </ng-template>
            <new-matchings *ngIf="matching" [matching]="matching" [project]="this.item"
                (doneFn)="backToMatching()"></new-matchings>
        </mat-tab>

        <!----------------------------------- CLIENT DETAILS ----------------------------------------------->

        <!-- <mat-tab [disabled]="item.values.status === 'new' || matchSelect">
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.CONTACTS}}</mat-icon>&nbsp;
                Clients
            </ng-template>

            <div class="mdc-layout-grid">
                <div class="mdc-layout-grid__inner">
                    <div class="mdc-layout-grid__cell"
                        *ngFor="let contact of item.values.synaps?.contactsProjectsArray">
                        <div style="position:relative">
                            <app-contact-tab [key]="contact.$key" model="projects" [modelKey]="item.values.$key">
                            </app-contact-tab>
                            <button mat-fab class="bottom-right p2" color="basic" (click)="goItem('contacts', contact)">
                                <mat-icon matListIcon>{{GLOBAL.ICONS.CONTACT}}</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <button mat-fab class="bottom-right pfixed p2" color="basic"
                (click)="select.init(item, db, 'contactsProjects')">
                <mat-icon matListIcon>sync_alt</mat-icon>
            </button>
        </mat-tab> -->

    </mat-tab-group>
</div>

<mat-spinner [strokeWidth]="5" class="fixed" *ngIf="item.saving || !(pageConfig.loaded || new) "></mat-spinner>



<button *ngIf="matchSelect" mat-fab color="accent" class="bottom-right pfixed" (click)="saveVisitMatching()">
    <mat-icon matListIcon>check</mat-icon>
</button>
<button *ngIf="matchSelect" mat-fab color="basic" class="bottom-right pfixed p2" (click)="cancelVisitMatching()">
    <mat-icon matListIcon>close</mat-icon>
</button>


<!-- DELETE -->
<button *ngIf="!new && !pageConfig.edit && isRole('admin')" mat-fab class="bottom-left pfixed" color="basic"
    (click)="db.deleteItem(item)">
    <mat-icon matListIcon>delete</mat-icon>
</button>