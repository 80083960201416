import { Injectable } from '@angular/core';
import { MainService } from '../../common/services/main/main.service';
import { AuthService } from '../../common/services/auth/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Project } from './project.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends MainService {

  public status: string[] = ['new', 'discovery', 'active', 'offer', 'signAgree', 'signDeed', 'signLease', 'payment', 'pause']
  public summary: { [id: string]: number } = {};
  public refMode = true;

  constructor(
    public auth: AuthService,
    public afs: AngularFirestore,
  ) {
    super(auth, afs, 'project', 'projects', Project);

    this._filters = {
      search: '',
      server: {
        filters: { referral: null, referralAgency: null },
        multiFilters: { status: [...this.status] },
        realtor: this.selectRealtorFilter(),
        agency: this.selectAgencyFilter(),
      },
      local: {
        localSearch: '',
        sort: { active: 'followUp', direction: 'asc' },
      }
    };

  }

  protected _refreshSummary() {
    this.summary = {};
    for (const item of this.items) {
      if (this.status.includes(item.status)) {
        this.summary[item.status] ? this.summary[item.status]++ : this.summary[item.status] = 1;
        if (item.status === 'active' && item.temperature) {
          this.summary[item.temperature] ? this.summary[item.temperature]++ : this.summary[item.temperature] = 1;
        }
      }
    }
  }

  protected _afterRefreshList() {
    this._refreshSummary();
  }

  protected _afterLocalFilter() {
    // this._refreshSummary();
  }

  protected _localCustomOrder(direction: number): boolean {
    if (this._filters.local.sort.active === 'icon') {
      const statusPriority = {
        new: 1,
        discovery: 2,
        offer: 3,
        signAgree: 4,
        signDeed: 5,
        signLease: 5,
        payment: 6,
        active: 7, //8 et 9
        pause: 10,
        deal: 11,
        failed: 12,
      }

      const temperature = {
        hot: 0,
        warm: 1,
        cold: 2,
      }

      this.itemsFilteredOrdered = [...this.itemsFiltered.sort((a, b) => {

        const scoreA = a.status === 'active' ? statusPriority[a.status] + temperature[a.temperature ? a.temperature : 'cold'] : statusPriority[a.status];
        const scoreB = b.status === 'active' ? statusPriority[b.status] + temperature[b.temperature ? b.temperature : 'cold'] : statusPriority[b.status];

        if (scoreA !== scoreB) {
          return (scoreA < scoreB ? -1 : 1) * direction;
        } else if (a.dateNeed > b.dateNeed) {
          return -1 * direction;
        } else {
          return 1 * direction;
        }
      }
      )];
      return true;
    }

    return false;
  }
}

@Injectable({
  providedIn: 'root'
})
export class MyProjectService extends ProjectService {


  constructor(
    public auth: AuthService,
    public afs: AngularFirestore,
  ) {
    super(auth, afs);

    this._filters = {
      search: '',
      server: {
        multiFilters: { status: this.status },
        realtor: this.auth.uid,
      },
      local: {
        localSearch: '',
        sort: { active: 'icon', direction: 'asc' },
      }
    };

  }


  // protected _afterRefreshList() {
  //   this.summary = {};
  //   for (const item of this.items) {
  //     if (this._status.includes(item.status)) {
  //       this.summary[item.status] ? this.summary[item.status]++ : this.summary[item.status] = 1;
  //       if (item.status === 'active' && item.temperature) {
  //         this.summary[item.temperature] ? this.summary[item.temperature]++ : this.summary[item.temperature] = 1;
  //       }
  //     }
  //   }
  // }

}