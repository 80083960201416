<mat-card>
    <mat-card-header>
        <mat-card-title *ngIf="action.done">Marketing Checked</mat-card-title>
        <mat-card-title *ngIf="!action.done">Marketing Check</mat-card-title>
    </mat-card-header>
</mat-card>

<div class="actionButtons" *ngIf="!action.done && isMarketing()">
    <button mat-icon-button class="active" [disabled]="item.saving" (click)="validAction()">
        <mat-icon>task_alt</mat-icon>
    </button>
    <button mat-icon-button (click)="dateAction()">
        <mat-icon>event</mat-icon>
    </button>
</div>