import { ChangeDetectorRef, Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Upload } from 'src/common/services/goinfre/file.model';
import { GoinfreService } from 'src/common/services/goinfre/goinfre.service';
import { MainItem } from 'src/common/services/main/main.model';

@Component({
  selector: 'upload-images',
  templateUrl: './upload-images.component.html',
  styleUrls: ['./upload-images.component.scss']
})
export class UploadImagesComponent {

  dropzoneActive: boolean = false;
  currentUploads: Upload[] = [];
  photos: any[] = [];
  synap: string;
  moved: boolean = false;

  private _item: MainItem;

  @Input()
  set item(value: MainItem) {
    this._item = value;
    this.synap = this.getFileSynap();
    this.refreshPhotos();
  }

  get item(): MainItem {
    return this._item;
  }

  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  options: any;


  constructor(
    public goinfre: GoinfreService,
    protected _cd: ChangeDetectorRef,
  ) {
    this.options = {
      onUpdate: (event: any) => {
        this.changeOrder(event);
      }
    };

  }

  // ngOnInit(): void {
  //   this.synap = this.getFileSynap();
  //   this.refreshPhotos();
  //   // this.goinfre.setServerFilter(
  //   //   {
  //   //     arrayFilters: {
  //   //       'units': this.item.values.$key
  //   //     },
  //   //   }
  //   // );
  // }

  getFileSynap() {
    if (this.item.module === 'units') {
      return 'filesUnits';
    }
    if (this.item.module === 'residences') {
      return 'filesResidences';
    }
  }

  refreshPhotos() {
    this.photos = [];
    for (const fileKey in this.item.values.synaps[this.synap]) {
      this.photos.push(Object.assign({}, this.item.values.synaps[this.synap][fileKey], { $key: fileKey }));
    }
    this.photos.sort((a, b) => a.order - b.order);
  }

  dropzoneState($event: boolean) {
    this.dropzoneActive = $event;
  }

  handleDrop(fileList: FileList) {

    for (let j = 0; j < fileList.length; j++) {
      let newUpload = this.currentUploads.push(new Upload(fileList[j], this.item.imageConfig.path, this.item.imageConfig.filename()));
      this.currentUploads[newUpload - 1].progress$.subscribe(() => {
        this._cd.markForCheck();
      });
      this.goinfre.pushUpload(this.currentUploads[newUpload - 1], 'image', this.item).then((newfile) => {
        this._removeFromCurrentUploads(newfile.values);
        const values = JSON.parse(JSON.stringify(newfile.values));
        delete values.synaps;
        this.item.values.synaps[this.synap][newfile.values.$key] = newfile.values;
        this.refreshPhotos();
        this._cd.markForCheck();
        this.action.emit({ action: 'new', file: newfile });
      });
    }

  }

  changeOrder(e) {
    if (e) {
      for (const [index, photo] of this.photos.entries()) {
        this.item.values.synaps[this.synap][photo.$key].order = index;
      }
      this.moved = true;
      this._cd.markForCheck();
      this.action.emit({ action: 'order' });
    }
  }

  saveOrder() {
    this.moved = false;
    for (const [index, photo] of this.photos.entries()) {
      this.item.values.synaps[this.synap][photo.$key].order = index;
    }
    this.goinfre.saveItemSynapInfo(this.item, this.synap);
    this.action.emit({ action: 'saveOrder' });
  }

  private _removeFromCurrentUploads(fileUploaded) {
    for (let j = this.currentUploads.length - 1; j >= 0; j--) {
      if (fileUploaded.name.includes(this.currentUploads[j].name)) {
        this.currentUploads.splice(j, 1);
      }
    }
  }

  removeFile(file: any) {
    delete this.item.values.synaps[this.synap][file.$key];
    this.refreshPhotos();

    this.goinfre.deleteFileUpload(file, this.item)
      .then(() => {
        this.action.emit({ action: 'delete', file: file });
      })
      .catch((error) => {
        if (error.code === 'storage/object-not-found') {
          this.action.emit({ action: 'delete', file: file });
        }

      });
  }

  toggleVisibility(fileIndex: number) {
    // if (!this.fileRecords[fileIndex].info) {
    //   this.fileRecords[fileIndex].info = {};
    //   this.fileRecords[fileIndex].info.online = false;
    // }
    // this.fileRecords[fileIndex].info.online = !this.fileRecords[fileIndex].info.online;
    // this.action.emit({ action: 'infoUpdate' });
  }

  togglePortal(fileIndex: number) {
    // if (!this.fileRecords[fileIndex].info) {
    //   this.fileRecords[fileIndex].info = {};
    //   this.fileRecords[fileIndex].info.portal = false;
    // }
    // this.fileRecords[fileIndex].info.portal = !this.fileRecords[fileIndex].info.portal;
    // this.action.emit({ action: 'infoUpdate' });
  }

}
