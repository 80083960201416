import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy, ChangeDetectorRef, Inject } from '@angular/core';
import { SettingService } from '../../services/setting/setting.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService, PageConfig } from '../../services/main/main.service';
import { Item } from '../../services/main/main.model';
import { SelectService } from '../../services/select/select.service';
import { GlobalConstants } from '../../../../functions/src/shared/global-constants';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// import { FileRecord } from '../../services/upload-file/upload-file-model';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { createList } from 'functions/src/shared/list';
import { Action } from 'src/services/action/action.model';
import { ActionService } from 'src/services/action/action.service';
import { MatDialog } from '@angular/material/dialog';
import { DatetimeDialogComponent } from 'src/common/components/datetime-dialog/datetime-dialog.component';
import { mergeDeep } from 'functions/src/shared/tools';
import { Unit } from 'src/services/unit/unit.model';


declare var google: any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainComponent implements OnInit, OnDestroy {

  public GLOBAL = new GlobalConstants();

  private ngUnsubscribe = new Subject();
  public item: Item;
  public newItem: Item;
  public new = true;
  protected _$key = '0';
  public bckpValues: any = {};
  // public fields: { [key: string]: Field } = {};

  // public actionService: ActionService = null;
  public actionItem = new Action(null, this.setting);

  public imageConf: { path: string, filename: string };

  public pageConfig: PageConfig = {
    edit: false,
    editTab: null,
    mainTab: null,
    loaded: false,
  };

  @Input() set key(key: string) {
    this._$key = key;
  }

  constructor(
    protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: MainService,
    public select: SelectService,
    protected route: ActivatedRoute,
    protected router: Router,
    public backURL: BackurlService,
    public dialog: MatDialog,
    public actionService?: ActionService) {


    // Manage Back URL if first page loaded
    if (!backURL.url) {
      backURL.addURL('/' + this.db.moduleListName);
    }


  }

  ngOnInit(): void {
    this._mainNgOnInit();
  }

  protected _mainNgOnInit() {
    if (this._$key == '0') {
      this._$key = this.route.snapshot.paramMap.get('id');
    }

    if (this._$key && this._$key != '0') {
      // EXISTING ITEM
      this.new = false;
      this.db.initItem(this);
      this._cd.markForCheck();

      this._bindItem().then(() => {
        this.pageConfig.loaded = true;
        this._itemOnInit();
        this.item.loading = false;
        this._cd.markForCheck();
      });
    } else {
      // NEW ITEM
      this.pageConfig.edit = true;
    }

    // Reset Selection to be safe if not creating a new item
    //Otherwise prefil the item if needed
    // if (this.select.active && !this.new) {
    //   this.select.reset();
    // }

  }


  protected _itemOnInit() {

  }

  ngOnDestroy(): void {
    this.db.recordPageConfig(this.item.values, this.pageConfig);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this._ngOnDestroy();
  }

  protected _ngOnDestroy() {
  }

  protected _bindItem(): Promise<any> {
    if (this._$key && this._$key != '0') {
      return this.db.getData(this._$key).then(data => {
        this.item.fromFB(data);
        this.db.bindItemUpdates(this.item).pipe(takeUntil(this.ngUnsubscribe)).subscribe((newValues: any) => {
          // this.fields = this.item.fields;
          this.item.values = mergeDeep({}, this.item.values);
          // TODO: only putback not changed values....
          this.bckpValues = mergeDeep({}, this.item.values);
          this.item.loading = false;
          this._afterBindUpdates();
          this._cd.markForCheck();
          this._cd.detectChanges();
        });
      });
    } else {
      this._$key = '0';
      return Promise.resolve();
    }
  }

  protected _afterBindUpdates() {
  }

  goPage(moduleName: string) {
    if (moduleName) {
      // this.backURL.addURL(`/${this.db.moduleListName}/${this.item.values.$key}`);
      this.backURL.addURL(`/${this.router.url}`);
      this.router.navigate([`/${moduleName}`]);
    }
  }

  goItem(moduleName: string, targetItem: any) {
    if (targetItem && targetItem.$key) {
      this.backURL.addURL(`/${this.db.moduleListName}/${this.item.values.$key}`);
      this.router.navigate([`/${moduleName}/${targetItem.$key}`]);
    }
  }
  goBack() { //TODO: Manage direct page access back URL. Probably can settup a defautl back URL is none in the page component...
    const backURL = this.backURL.back();
    if (backURL) {
      this.router.navigate([backURL]);
    } else {
      this.router.navigate(['/']);
    }
  }
  goTab(tab: number) {
    if (tab) {
      this.pageConfig.mainTab = tab;
    }
  }
  editItem() {
    this.pageConfig.edit = true;
  }

  cancelEdit(cancelEdit: boolean = false) {
    this.item.values = mergeDeep({}, this.bckpValues);
    this._cd.markForCheck();
    // this.item.valid = false;
    this.item.modified = false;
    if (cancelEdit) {
      this.pageConfig.edit = false;
    }
  }

  displayItem() {
    this.pageConfig.edit = false;
  }

  cancelEditDisplayItem() {
    this.cancelEdit();
    this.displayItem();
  }

  async saveNewItem() {
    this._beforeSaveNewItem();
    // this.pageConfig.edit = false;
    this.db.saveNewItem(this.item).then(async ($key) => {

      if (this.select.active) {
        this.select.prefil = null;
        this.select.toggle(createList(this.item.values, 'contacts'));
        this.goBack();
      } else {
        this._$key = $key;
        this.new = false;

        this.db.bindItemUpdates(this.item).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
          // this.fields = this.item.fields;
          this.item.values = mergeDeep({}, this.item.values);
          this._cd.markForCheck();
          this.bckpValues = mergeDeep({}, this.item.values);
          this.pageConfig.loaded = true;
          this._updateURL();
          this._afterBindUpdates();
        });

        this._afterSaveNewItem();
        this._itemOnInit();
      }
    });
  }

  protected _beforeSaveNewItem() {

  }

  protected _afterSaveNewItem() {

  }

  protected _updateURL() {
    window.history.pushState('page2', 'Title', `/${this.db.moduleListName}/${this.item.values.$key}`);
  }

  saveItem(closeEdit: boolean = true): Promise<any> {
    this.beforeSaveItem();
    this.pageConfig.edit = !closeEdit;
    return this.db.saveItem(this.item).then(() => {
      this.item.modified = false;
    });
  }

  saveItemDisplayItem() {
    this.saveItem();
    this.displayItem();
  }

  startSaving() {
    this.db.startSaving(this.item);
  }

  protected beforeSaveItem() { }

  saveItemAndClose() {
    this.saveItem();
    this.pageConfig.edit = false;
  }

  getFilesUnitsLength(filesUnits: any): number {
    return filesUnits ? Object.keys(filesUnits).length : 0;
  }

  saveAddress(address: any) {
    Object.assign(this.item.values.address, address);
    this.item.modify.address = { gps: true, eGps: true, sectors: true, sector: true };

    this.saveItem();
  }



  archiveItem() {
    // this.db.archiveItem(this.item);
  }

  deleteItem() {
    this.db.deleteItem(this.item);
  }

  refresh() {
    this.item.values = mergeDeep({}, this.item.values);
    this._cd.markForCheck();
  }

  isOneOfRoles(roles: string[]): boolean {
    return this.db.isOneOfRoles(roles);
  }

  isRole(role: string): boolean {
    return this.db.isRole(role);
  }

  isRealtor(): boolean {
    return this.item.values.realtor === this.db.auth.uid || this.isOneOfRoles(['admin', 'director', 'assistant']);
  }

  isAccountant(): boolean {
    return this.isOneOfRoles(['admin', 'accountant']);
  }

  isMarketing(): boolean {
    return this.isOneOfRoles(['admin', 'marketing']);
  }

  logs() {
    this.backURL.addURL(`/${this.db.moduleListName}/${this.item.values.$key}`);
    this.router.navigate([`/logs`, this.db.moduleListName, this.item.values.$key]);
  }

  newTab(url: string) {
    window.open(url, '_blank');
  }

  copyClipboard(copy: string) {
    navigator.clipboard.writeText(copy);
  }

  public dateAction() {
    const dialogRef = this.dialog.open(DatetimeDialogComponent, {
      width: '250px',
      disableClose: true,
      data: this.item.values.date,
    });

    dialogRef.afterClosed().subscribe(newDate => {
      if (newDate) {
        this.item.values.date = newDate;
        this.item.modify.date = true;
        this._cd.detectChanges();
        this.saveItem();
      }
    });
  }

  get actionSynap(): any {
    return {}
  }


}


