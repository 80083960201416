<div class="mdc-layout-grid dropzone" [ngClass]="{'active': dropzoneActive}" fileDrop
    (filesDropped)="handleDrop($event)" (filesHovered)="dropzoneState($event)">
    <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell--span-12" *ngFor="let uploadPhoto of currentUploads">
            <mat-card>
                <mat-card-content style="position: relative;">
                    <mat-progress-bar [mode]="uploadPhoto.progress == 100 ? 'indeterminate' : 'determinate'"
                        [value]="uploadPhoto.progress"></mat-progress-bar>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div class="mdc-layout-grid__inner" [sortablejs]="photos" [sortablejsOptions]="options" *ngIf="!currentUploads.length">
        <div class="mdc-layout-grid__cell--span-3" *ngFor="let photo of photos; let i = index">
            <mat-card class="full">

                <div class="image-con">
                    <img [src]="photo | thumb:'400'">
                </div>

                <mat-card-actions>
                    <!-- <div>
                        <button mat-icon-button (click)="toggleVisibility(i)">
                            <mat-icon [ngClass]="{selected: photo.info?.online}">visibility</mat-icon>
                        </button>
                        <button mat-icon-button (click)="togglePortal(i)">
                            <mat-icon [ngClass]="{selected: photo.info?.portal}">language</mat-icon>
                        </button>
                    </div> -->
                    <button mat-icon-button (click)="removeFile(photo)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
    <!-- SAVE -->
    <button *ngIf="moved" mat-fab class="bottom-right pfixed"
        (click)="saveOrder()">
        <mat-icon matListIcon>check</mat-icon>
    </button>
</div>