<mat-card class="slide-wrapper" [ngClass]="{fsmode: fullscreen}">
    <ng-container *ngIf="last">
        <div class="image-container">
            <ng-container *ngFor="let photo of photos; let i = index">
                <img [ngClass]="{show: i == pos}" [src]="photo | thumb:dim">
            </ng-container>
        </div>

        <div *ngIf="photos.length" class="pos">{{pos + 1}}/{{total}}</div>

        <button class="nav before large" mat-icon-button color="accent" (click)="previous()">
            <mat-icon>navigate_before</mat-icon>
        </button>
        <button class="nav next large" mat-icon-button color="accent" (click)="next()">
            <mat-icon>navigate_next</mat-icon>
        </button>

        <button *ngIf="!fullscreen" class="fullscreen large" mat-icon-button (click)="toggleFullscreen()">
            <mat-icon>fullscreen</mat-icon>
        </button>
        <button *ngIf="fullscreen" class="fullscreen large" mat-icon-button (click)="toggleFullscreen()">
            <mat-icon>fullscreen_exit</mat-icon>
        </button>
    </ng-container>
    <ng-container *ngIf="!last">
        <mat-icon class="nophoto" color="accent">photo_library</mat-icon>
    </ng-container>
</mat-card>