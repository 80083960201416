import { Injectable } from '@angular/core';
import { MainService } from '../../common/services/main/main.service';
import { AuthService } from '../../common/services/auth/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Unit } from './unit.model';

@Injectable({
  providedIn: 'root'
})
export class UnitService extends MainService {

  public refMode = true;

  constructor(
    public auth: AuthService,
    public afs: AngularFirestore,
  ) {
    super(auth, afs, 'unit', 'units', Unit);

    this.itemsPageConfig = {
      map: false,
      sideTab: 0,
      mapParams: {
        'bounds': null,
        'lat': -20.2674,
        'long': 57.5766,
        'zoom': 10,
      }
    }
  }

  async duplicateUnit(unit: Unit): Promise<Unit> {
    const newUnit = new Unit(unit.toFB('new'), unit.setting);
    newUnit.values.synaps = {};
    if (unit.values.synaps.residenceUnits) {
      newUnit.values.synaps.residenceUnits = unit.values.synaps.residenceUnits;
    }
    // if (unit.values.synaps.filesUnits){
    //   newUnit.values.synaps.filesUnits = unit.values.synaps.filesUnits;
    // }
    newUnit.values.$key = null;
    newUnit.values.ref = null;
    // newUnit.values.photos = {};
    await this.saveNewItem(newUnit);
    return newUnit;
  }

  protected _stopFilter(v: any): boolean {
    if (this._filters.local.budget && this._filters.server.arrayFilters) {
      const budget = this._filters.local.budget;
      if (this._filters.server.arrayFilters.mandateTypes == 'sale') {
        if (!v.salePrice
          || (budget.saleMinPrice && v.salePrice < budget.saleMinPrice)
          || (budget.saleMaxPrice && v.salePrice > budget.saleMaxPrice)
        ) {
          return true;
        }
      } else if (this._filters.server.arrayFilters.mandateTypes == 'rental') {
        if (!v.rentalPrice
          || (budget.rentalMinPrice && v.rentalPrice < budget.rentalMinPrice)
          || (budget.rentalMaxPrice && v.rentalPrice > budget.rentalMaxPrice)
        ) {
          return true;
        }
      }
    }
    return false;
  }

}