import { Lang, MainItem, Field, Address, AddressFields, Alert, ExternalMediaRecord } from '../../common/services/main/main.model';
import { SettingService } from '../../common/services/setting/setting.service';
import { mergeResidenceUnitSynap, slugLangUnit, slugify } from 'functions/src/shared/tools';
import * as firebase from 'firebase/app';


export class Unit extends MainItem {

    values: {
        $key: string,
        ref: number,
        created: firebase.firestore.FieldValue,
        createdBy: string,
        updated: firebase.firestore.FieldValue,
        updatedBy: string,
        alerts: Alert[],

        synaps: any,

        vefa: boolean,
        type: string,
        typeDisp: string,
        landType: string,
        leasehold: boolean,
        townhouse: boolean,
        penthouse: boolean,
        garden: boolean,
        yard: boolean,
        address: Address,
        tel: string,

        pm: boolean,

        condition: string,
        builtYear: number,
        style: string,
        exposition: string,

        bedrooms: number,
        bathrooms: number,
        toilets: number,
        rooms: number,
        carSpaces: { [id: string]: number },
        builtSurface: number,
        livingSurface: number,
        landSurface: number,
        landUnit: string,
        gardenSurface: number,
        gardenUnit: string,
        includeAC: boolean, // number of A/C
        nbAC: number, // number of A/C
        floor: string, // etage si immeuble
        topFloor: string // Top Floor
        storeys: string, // nomber d'etages dans l'unité
        waterHeaterType: string,
        kitchenType: string,
        syndicFee: number,
        vefaName: string, // VEFA only
        vefaNb: number, // VEFA only
        vefaAv: number, // VEFA only

        title: Lang,
        keyPoints: { [id: string]: string },
        desc: Lang,
        descs: { [id: string]: Lang },
        slug: Lang,

        params: { [id: string]: string | boolean },
        equipment: { [id: string]: string | boolean },
        appliances: { [id: string]: string | boolean },
        security: { [id: string]: string | boolean },
        view: { [id: string]: string | boolean },
        area: { [id: string]: string | boolean },

        // photos: { [id: string]: FileRecord },
        vr: ExternalMediaRecord,
        video: ExternalMediaRecord,

        // activeMandates: { sale: any; rent: any } | null;
        // residence: any;
        // marketingCheck: boolean,
        syncWeb: {
            rental?: { [id: string]: { id: string, online: boolean, udpated: Date, errors: any } },
            sale?: { [id: string]: { id: string, online: boolean, udpated: Date, errors: any } },
        },
        error: boolean,
        score: number,
    }

    constructor(
        values: any = null,
        public setting?: SettingService,
    ) {
        super(
            'units',
            {
                vefa: new Field({
                    label: 'VEFA',
                    input: 'boolean',
                    default: false,
                }),
                type: new Field({
                    label: 'Type', input: 'select', inputOptions: { param: 'unitTypes' },
                    validations: { required: true },
                }),
                typeDisp: new Field({
                    input: 'auto', inputOptions: { param: 'unitTypeDisps' },
                    autoFn: {
                        toFB: (values, setting) => { return this._typeDisp(values, setting) }
                    },

                }),
                landType: new Field({
                    label: 'Land Type',
                    input: 'select',
                    inputOptions: { param: 'landTypes' },
                    show: (data: any) => data && (data.type === 'land' || data.type === 'house'),
                    validations: { required: (data: any) => data && data.type === 'land' || data.type === 'house' },
                    default: 'residential',

                }),
                leasehold: new Field({ label: 'Leasehold', input: 'boolean', default: false }),
                townhouse: new Field({
                    label: 'Townhouse',
                    input: 'boolean',
                    default: false,
                    show: (data: any) => data && data.type === 'house',
                }),
                penthouse: new Field({
                    label: 'Penthouse',
                    input: 'boolean',
                    default: false,
                    show: (data: any) => data && data.type === 'apartment',

                }),
                garden: new Field({
                    label: 'Garden',
                    input: 'boolean',
                    default: false,
                    show: (data: any) => data && data.type === 'house' || (data.floor === 'l0' && (data.type === 'apartment' || data.type === 'office')),

                }),
                address: new Field({
                    label: 'Address',
                    input: 'objectSub',
                    sub: new AddressFields(),
                }),
                pm: new Field({
                    label: 'Property Management',
                    input: 'boolean',
                    default: false,
                }),
                condition: new Field({
                    label: 'Condition',
                    input: 'select',
                    inputOptions: {
                        param: 'unitConditionsVEFA',
                    },
                    show: (data: any) => data && !data.vefa
                }),
                builtYear: new Field({
                    input: 'number', label: 'Delivery Year',
                    inputOptions: { noComa: true },
                    show: (data: any) => data && data.type !== 'land',
                }),
                style: new Field({
                    label: 'Style',
                    input: 'select',
                    inputOptions: { param: 'unitStyles' },
                    show: (data: any) => data && data.type !== 'land',
                }),
                exposition: new Field({
                    label: 'Exposition',
                    input: 'select',
                    inputOptions: { param: 'expositions' },
                }),
                // rooms: new Field({
                //     input: 'number', label: 'Nb of Rooms',
                //     show: (data: any) => data && data.type !== 'land'
                // }),
                bedrooms: new Field({
                    input: 'number', label: 'Nb of Bedrooms',
                    show: (data: any) => data && (data.type === 'house' || data.type === 'apartment' || data.type === 'hotel'),
                    icon: 'single_bed',
                }),
                bathrooms: new Field({
                    input: 'number', label: 'Nb of Bathrooms',
                    show: (data: any) => data && data.type !== 'land',
                    icon: 'bathtub',
                }),
                toilets: new Field({
                    input: 'number', label: 'Nb of Toilets',
                    icon: 'wc',
                    show: (data: any) => data && data.type !== 'land',
                }),
                carSpaces: new Field({
                    label: 'Car Spaces',
                    input: 'objectSub',
                    // show: (data: any) => data && data.type !== 'land',
                    sub: {
                        outside: new Field({ input: 'number', label: 'Outside Parkings' }),
                        garage: new Field({ input: 'number', label: 'Garage Spaces' }),
                        carport: new Field({ input: 'number', label: 'Carport Spaces' }),
                    }
                }),
                builtSurface: new Field({
                    input: 'number',
                    label: 'Build Surface (m²)',
                    show: (data: any) => data && data.type !== 'land',

                }),
                livingSurface: new Field({
                    input: 'number',
                    label: 'Interior Surface (m²)',
                    show: (data: any) => data && data.type !== 'land',
                    icon: 'architecture',
                }),
                landSurface: new Field({
                    input: 'number', label: 'Land Surface',
                    icon: 'crop_free',
                }),
                landUnit: new Field({
                    label: 'Land Unit',
                    input: 'select',
                    inputOptions: { param: 'landUnits' },
                    default: 'm2',
                }),
                gardenSurface: new Field({
                    input: 'number',
                    label: 'Garden Surface',
                    show: (data: any) => data && data.garden,
                    icon: 'local_florist',
                }),
                gardenUnit: new Field({
                    label: 'Garden Unit',
                    input: 'select',
                    inputOptions: { param: 'landUnits' },
                    default: 'm2',
                    show: (data: any) => data && data.garden,
                }),
                includeAC: new Field({
                    label: 'A/C',
                    input: 'boolean',
                    default: false,
                    show: (data: any) => data && data.type !== 'land',
                    icon: 'ac_unit',
                }),
                nbAC: new Field({
                    input: 'number',
                    label: 'Nb of A/C',
                    show: (data: any) => data && data.includeAC && data.type !== 'land',
                }),
                floor: new Field({
                    label: 'Floor Level',
                    input: 'select',
                    inputOptions: { param: 'unitFloors' },
                    show: (data: any) => data && data.type !== 'land' && data.type !== 'house',
                }),
                topFloor: new Field({
                    label: 'Top Floor', input: 'select', inputOptions: { param: 'unitFloors' },
                    show: (data: any) => data && data.type !== 'land' && data.type !== 'house',

                }),
                score: new Field({ input: 'number', }),
                storeys: new Field({
                    label: 'Number of Storeys',
                    input: 'select',
                    inputOptions: { param: 'unitStoreys' },
                    show: (data: any) => data && data.type !== 'land',
                }),
                waterHeaterType: new Field({
                    label: 'Water Heater Type',
                    input: 'select',
                    inputOptions: { param: 'waterHeaterTypes' },
                    show: (data: any) => data && data.type !== 'land',
                }),
                kitchenType: new Field({
                    label: 'Kitchen Type',
                    input: 'select',
                    inputOptions: { param: 'kitchenTypes' },
                    show: (data: any) => data && data.type !== 'land' && data.type !== 'office',

                }),
                syndicFee: new Field({
                    input: 'number', label: 'Syndic Fee (Rs / month)',
                    show: (data: any) => data && data.synaps && data.synaps.residenceUnitsObj,
                    icon: 'single_bed',
                }),
                vefaName: new Field({
                    label: 'Name',
                    show: (data: any) => data && data.vefa,
                }),
                vefaNb: new Field({
                    input: 'number', label: 'Total Nb of Units',
                    show: (data: any) => data && data.vefa,
                    validations: { required: true },
                }),
                vefaAv: new Field({
                    input: 'number', label: 'Units Available',
                    show: (data: any) => data && data.vefa,
                    validations: { required: true },
                }),
                // marketingCheck: new Field({
                //     input: 'auto', inputOptions: { param: 'unitTypeDisps' },
                //     autoFn: {
                //         toFB: (values: any) => { return !(values.alerts && values.alerts.length) }
                //     },
                // }),
                keyPoints: new Field({
                    label: 'Key Points',
                    input: 'dynSub',
                    dynSub: {
                        text: new Field({ label: 'Key Point', checks: { trim: true } }),
                    },
                }),
                title: new Field({ multiLang: true, label: 'Title' }),
                desc: new Field({ multiLang: true, input: 'textarea', label: 'Description' }),
                descs: new Field({
                    label: 'Other Descriptions',
                    input: 'objectSub',
                    sub: {
                        portals: new Field({ multiLang: true, label: 'Portals', input: 'textarea', }),
                        facebook: new Field({ multiLang: true, label: 'Facebook', input: 'textarea', }),
                        instagram: new Field({ multiLang: true, label: 'Instagram', input: 'textarea', }),
                    },

                }),
                slug: new Field({
                    input: 'auto',
                    filters: { new: false, save: true },
                    autoFn: {
                        toFB: (values) => {
                            let residence = null;
                            if (values.synaps && values.synaps.residenceUnitsObj) {
                                residence = values.synaps.residenceUnitsObj;
                            }
                            return {
                                en: slugify(slugLangUnit(values, residence, 'en')),
                                fr: slugify(slugLangUnit(values, residence, 'fr')),
                                de: slugify(slugLangUnit(values, residence, 'de')),
                                ru: slugify(slugLangUnit(values, residence, 'ru')),
                            }
                        }
                    },
                }),
                params: new Field({
                    label: 'Layout & Rooms',
                    input: 'objectSub',
                    sub: {
                        privatePool: new Field({ label: 'Private Pool', input: 'boolean', icon: 'pool', }),
                        veranda: new Field({ label: 'Veranda', input: 'boolean', }),
                        balcony: new Field({ label: 'Balcony', input: 'boolean', }),
                        laundry: new Field({ label: 'Laundry', input: 'boolean', }),
                        dressing: new Field({ label: 'Dressing', input: 'boolean', }),
                        office: new Field({ label: 'Office', input: 'boolean', }),
                        tvRoom: new Field({ label: 'TV Room', input: 'boolean', }),
                        cave: new Field({ label: 'Cave', input: 'boolean', }),
                        rooftopAccess: new Field({ label: 'Rooftop Access', input: 'boolean', }),
                        yard: new Field({
                            label: 'Yard', input: 'boolean',
                            show: (data: any) => data && data.type === 'house' || (data.floor === 'l0' && (data.type === 'apartment' || data.type === 'office')),
                        }),
                        privateGym: new Field({ label: 'Private Gym', input: 'boolean', }),
                        privateTennis: new Field({ label: 'Private Tennis', input: 'boolean', }),
                        privateSpa: new Field({ label: 'Private Spa', input: 'boolean', }),
                        handicapAccess: new Field({ label: 'Handicap Access', input: 'boolean', }),
                    },

                }),
                equipment: new Field({
                    label: 'Equipment & Services',
                    input: 'objectSub',
                    sub: {
                        furnished: new Field({ label: 'Furnished', input: 'boolean', }),
                        semifurnished: new Field({ label: 'Semi-Furnished', input: 'boolean', }),
                        unfurnished: new Field({ label: 'Unfurnished', input: 'boolean', }),
                        fullyEquippedKitchen: new Field({ label: 'Fully Equipped Kitchen', input: 'boolean', }),
                        petAllowed: new Field({ label: 'Pet Allowed', input: 'boolean', }),
                        generator: new Field({ label: 'Generator', input: 'boolean', }),
                        privateLift: new Field({ label: 'Private Lift', input: 'boolean', }),
                        waterTank: new Field({ label: 'Water Tank', input: 'boolean', }),
                        automatedGarage: new Field({ label: 'Automated Garage', input: 'boolean', }),
                        centralizedVentilation: new Field({ label: 'Centralised Ventilation System', input: 'boolean', }),
                        fireplace: new Field({ label: 'Fireplace', input: 'boolean', }),


                        energyEfficiency: new Field({ label: 'Energy Efficiency High', input: 'boolean', }),

                        houseCleaning: new Field({ label: 'House Cleaning', input: 'boolean', }),
                        cookingService: new Field({ label: 'Cooking Service', input: 'boolean', }),
                        soundproof: new Field({ label: 'Soundproof', input: 'boolean', }),
                        sewageSystem: new Field({ label: 'Sewage System', input: 'boolean', }),
                    }
                }),
                appliances: new Field({
                    label: 'Appliances',
                    input: 'objectSub',
                    sub: {
                        opticalFiber: new Field({ label: 'Optical Fiber', input: 'boolean', }),
                        phoneLine: new Field({ label: 'Phone Line', input: 'boolean', }),
                        wifi: new Field({ label: 'Wifi/Internet', input: 'boolean', }),
                        TV: new Field({ label: 'Television', input: 'boolean', }),
                        satelliteTV: new Field({ label: 'Satellite TV', input: 'boolean', }),
                        dishwasher: new Field({ label: 'Dishwasher', input: 'boolean', }),
                        washingMachine: new Field({ label: 'Washing Machine', input: 'boolean', }),
                    }
                }),
                security: new Field({
                    label: 'Security',
                    input: 'objectSub',
                    sub: {
                        alarm: new Field({ label: 'Alarm System', input: 'boolean', }),
                        securityBars: new Field({ label: 'Security Bars', input: 'boolean', }),
                        fenced: new Field({ label: 'Fenced', input: 'boolean', }),
                        firealarm: new Field({ label: 'Fire Alarm', input: 'boolean', }),
                        watchman: new Field({ label: 'Watchman', input: 'boolean', }),
                        securityGuard: new Field({ label: 'Security Guard', input: 'boolean', }),
                        secureParking: new Field({ label: 'Secured Parking', input: 'boolean', }),
                    }
                }),
                view: new Field({
                    label: 'View',
                    input: 'objectSub',
                    sub: {
                        seaView: new Field({ label: 'Sea View', input: 'boolean', }),
                        mountainView: new Field({ label: 'Mountain View', input: 'boolean', }),
                        riverView: new Field({ label: 'River View', input: 'boolean', }),
                        golfView: new Field({ label: 'Golf View', input: 'boolean', }),
                    }
                }),
                area: new Field({
                    label: 'Area',
                    input: 'objectSub',
                    sub: {
                        beachfront: new Field({ label: 'Seafront', input: 'boolean', }),
                        seaAccess: new Field({ label: 'Sea Access', input: 'boolean', }),
                        nearSchool: new Field({ label: 'Close to School', input: 'boolean', }),
                        nearBus: new Field({ label: 'Close to Bus Stop', input: 'boolean', }),
                        nearGolf: new Field({ label: 'Close to Golf', input: 'boolean', }),
                        nearHospital: new Field({ label: 'Close to Hospital', input: 'boolean', }),
                        nearShops: new Field({ label: 'Close to Shops', input: 'boolean', }),
                    }
                }),
                // photos: new Field({
                //     input: 'dynSub',
                //     dynSub: {
                //         name: new Field(),
                //         path: new Field(),
                //         type: new Field(),
                //         url: new Field(),
                //         info: new Field({
                //             input: 'objectSub',
                //             sub: {
                //                 online: new Field({
                //                     input: 'boolean', default: true,
                //                 }),
                //                 portal: new Field({
                //                     input: 'boolean', default: true,
                //                 }),
                //             }
                //         }),
                //     },
                // }),
                // photo: new Field({
                //     input: 'auto',

                //     autoFn: {
                //         toFB: (values, setting) => {
                //             if (values.photos && values.photos.length) {
                //                 return values.photos[0];
                //             }
                //             return null;
                //         }
                //     },

                // }),
                vr: new Field({
                    label: 'Virtual Tour',
                    input: 'objectSub',
                    sub: {
                        url: new Field({ label: 'URL', input: 'text', }),
                        ad: new Field({ label: 'Advertise', input: 'boolean', }),
                    }
                }),
                video: new Field({
                    label: 'Video',
                    input: 'objectSub',
                    sub: {
                        url: new Field({ label: 'URL', input: 'text', }),
                        ad: new Field({ label: 'Advertise', input: 'boolean', }),
                    }
                }),
                syncWeb: new Field({ input: 'object' }),
                // List Parameters Only
                // residences_name: new Field({ label: 'Residence' }),
                // contacts_fullname: new Field({ label: 'Owner' }),
                // address_eCity: new Field({ label: 'City' }),
            },
            {
                display: {
                    title: null,
                    fields: ['landType', 'leasehold', 'storeys', 'floor', 'topFloor', 'garden', 'yard', 'nbAC', 'waterHeaterType', 'kitchenType', 'carSpaces', 'syndicFee', 'pm']
                },
                main: {
                    title: null,
                    fields: ['vefaName', 'vefaNb', 'vefaAv', 'type', 'landType', 'leasehold', 'townhouse', 'penthouse', 'storeys', 'floor', 'topFloor', 'garden', 'builtYear', 'syndicFee', 'pm']
                },
                address: {
                    title: null,
                    fields: ['address']
                },
                numbers: {
                    title: null,
                    fields: ['bedrooms', 'bathrooms', 'toilets', 'includeAC', 'nbAC', 'condition', 'style', 'kitchenType', 'waterHeaterType']
                },
                surface: {
                    title: null,
                    fields: ['builtSurface', 'livingSurface', 'landSurface', 'landUnit', 'gardenSurface', 'gardenUnit', 'carSpaces']
                },
                params: {
                    title: null,
                    fields: ['params']
                },
                equipment: {
                    title: null,
                    fields: ['equipment']
                },
                appliances: {
                    title: null,
                    fields: ['appliances']
                },
                security: {
                    title: null,
                    fields: ['security']
                },
                view: {
                    title: null,
                    fields: ['view']
                },
                area: {
                    title: null,
                    fields: ['area']
                },
                desc: {
                    title: null,
                    fields: ['keyPoints', 'title', 'desc']
                },
                externalMedias: {
                    title: null,
                    fields: ['video', 'vr']
                },
                newResidenceUnit: {
                    title: null,
                    fields: ['vefa', 'type', 'townhouse', 'penthouse', 'bedrooms', 'bathrooms', 'storeys', 'floor', 'vefaNb', 'vefaAv']
                }
            },
            {
                main: {
                    title: 'Property List',
                    columns: [
                        { field: 'icon', type: 'icon', label: 'Type' },
                        { field: 'ref', type: 'string', label: 'Ref', options: { prefix: 'P' } },
                        { field: 'bedrooms', type: 'string', label: 'Beds' },
                        { field: 'surface', type: 'string', label: 'm²' },
                        { field: 'city', type: 'string', label: 'City' },
                        { field: 'residence', type: 'string', label: 'Residence' },
                        { field: 'owners', type: 'string', label: 'Owners', options: { multi: true } },
                        { field: 'mandates', type: 'string', label: 'Mandates', options: { multi: true } }
                    ],
                },
            },
            {
                path: 'units/', filename: () => {
                    let filename = 'immobilier-ile-maurice-nestenn-';
                    filename += 'P' + this.values.ref;
                    return filename;
                }
            },
            setting,
        );
        if (values) {
            this.fromFB(values);
        }

    }



    private _typeDisp(values: any, setting: SettingService) {
        let unitTypeDisp = values.type;
        if (values.type == 'house' && values.townhouse) {
            unitTypeDisp = 'townhouse';
        } else if (values.type == 'apartment' && values.penthouse) {
            unitTypeDisp = 'penthouse';
        }
        return setting.getParamValueName('unitTypeDisps', unitTypeDisp);
    }


    public _checkAlerts(values: any, FBvalues: any) {

        let score = 20;
        let totalScore = 20;
        const alerts: Alert[] = [];

        // ERRORS
        if (!(values.address && values.address.gps)) {
            alerts.push({ type: 'error', code: 'missingGPS' });
        }
        if (!(values.address && values.address.eCity)) {
            alerts.push({ type: 'error', code: 'missingCity' });
        }
        if (!values.vefa && values.synaps && values.synaps.residenceUnits && Object.keys(values.synaps.residenceUnits).length > 0 && !(values.address && values.address.unitNb)) {
            alerts.push({ type: 'error', code: 'missingUnitNb' });
        }
        if (values.type === 'land') {
            if (!values.landType) {
                alerts.push({ type: 'error', code: 'missingLandType' });
            }
        }
        if (!(values.title && values.title.en && values.title.fr)) {
            alerts.push({ type: 'error', code: 'missingTitle' });
        }
        totalScore += 30;
        if (!(values.desc && values.desc.en && values.desc.fr)) {
            alerts.push({ type: 'error', code: 'missingDesc' });
        } else {
            if (this.values.desc.fr?.length > 500 && this.values.desc.en?.length > 500) {
                score += 20;
                if (this.values.desc.fr?.length > 100 && this.values.desc.en?.length > 100) {
                    score += 10;
                } else {
                    alerts.push({ type: 'marketing', code: 'short1000Desc' });
                }
            } else {
                alerts.push({ type: 'marketing', code: 'short500Desc' });
            }
        }


        if (values.type === 'apartment' || values.type === 'house') {
            if (values.bedrooms === null || isNaN(values.bedrooms)) {
                alerts.push({ type: 'error', code: 'missingBed' });
            }
            if (values.bathrooms === null || isNaN(values.bathrooms)) {
                alerts.push({ type: 'error', code: 'missingBath' });
            }
            if (!(values.builtSurface || values.livingSurface)) {
                alerts.push({ type: 'error', code: 'missingSurface' });
            }
            if (!values.condition) {
                alerts.push({ type: 'error', code: 'missingCondition' });
            }
        }

        if (values.type === 'land' || (values.type === 'house' && !values.townhouse)) {
            if (!values.landSurface) {
                alerts.push({ type: 'error', code: 'missingLand' });
            }
        }

        if (values.type === 'apartment') {
            if (!values.floor) {
                alerts.push({ type: 'error', code: 'missingFloor' });
            }
            if (!values.topFloor) {
                alerts.push({ type: 'error', code: 'missingTopFloor' });
            }
            if (!values.storeys) {
                alerts.push({ type: 'error', code: 'missingStoreys' });
            }
        }

        totalScore += 35;
        const photoCount = Object.keys(this.values.synaps?.filesUnits || {}).length;
        if (photoCount) {
            if (photoCount >= 35) {
                score += 35;
            } else if (photoCount >= 20) {
                score += 30;
                alerts.push({ type: 'marketing', code: 'less35Photos' });
            } else if (photoCount >= 15) {
                score += 25;
                alerts.push({ type: 'marketing', code: 'less20Photos' });
            } else if (photoCount >= 10) {
                score += 20;
                alerts.push({ type: 'marketing', code: 'less15Photos' });
            } else {
                alerts.push({ type: 'marketing', code: 'less10Photos' });
            }
        } else {
            alerts.push({ type: 'error', code: 'missingPhotos' });
        }

        totalScore += 20;
        if (this.values.video?.ad && this.values.video?.url?.length) {
            score += 20;
        } else {
            alerts.push({ type: 'marketing', code: 'missingVideo' });
        }

        if (alerts.some(alert => alert.type === 'error')) {
            FBvalues.score = 0;
        } else {
            FBvalues.score = Math.ceil(score / totalScore * 100);
        }
        FBvalues.updatedFields.score = firebase.firestore.FieldValue.serverTimestamp();

        FBvalues.alerts = alerts;
        FBvalues.updatedFields.alerts = firebase.firestore.FieldValue.serverTimestamp();
    }

    // private _slugLang(setting: SettingService, item: any, lang: string): string {
    //     let slug = '';

    //     let residence = null;
    //     if (item.synaps && item.synaps.residenceUnitsObj) {
    //         residence = item.synaps.residenceUnitsObj;
    //     }

    //     if (item.address && item.address.eCityName) {
    //         slug += item.address.eCityName;
    //     }
    //     if (residence && residence.name) {
    //         slug += ' ' + residence.name;
    //     }
    //     if (item.type) {
    //         slug += ' ' + setting.getParamValueLang('unitTypes', item.type, lang);
    //     }
    //     if (residence && residence.scheme) {
    //         slug += ' ' + setting.getParamValueLang('residenceSchemes', residence.scheme, lang);
    //     }
    //     slug += ' ' + item.ref;

    //     return slug;
    // }


    protected _fnSaveAutoData() {



        // configure condition parameter to vefa if it is
        if (this.values.vefa && this.values.condition !== 'vefa') {
            this.values.condition = 'vefa';
            this.modify.condition = true;
        } else if (this.values.condition === 'vefa' && !this.values.vefa) {
            this.values.vefa = true;
            this.modify.vefa = true;
        }

        // Automatic Name if needed
        if (this.values.vefa && !(this.values.vefaName && this.values.vefaName.length)) {
            this.values.vefaName = '';
            if (this.values.floor) {
                this.values.vefaName += this.values.floor;
            }
            if (this.values.penthouse) {
                if (this.values.vefaName.length) {
                    this.values.vefaName += '-';
                }
                this.values.vefaName += 'Pent';
            }
            if (this.values.bedrooms) {
                if (this.values.vefaName.length) {
                    this.values.vefaName += '/';
                }
                this.values.vefaName += this.values.bedrooms + '-bed';
            }
            if (this.values.bathrooms) {
                this.values.vefaName += '/' + this.values.bathrooms + '-bath';
            }
            this.modify.vefaName = true;
        }
    }

    protected _fnGetAutoData(): void {
        mergeResidenceUnitSynap(this.values);

        this.values.error = this._checkError(this.values);
    }


}