import { PreSettings } from '../shared/setting-model';

// Integrate residence data in unit object and return the residence
export function mergeResidenceUnitSynap(unit: any): any {

    let residence: any = null;
    if (unit.synaps && unit.synaps.residenceUnits) {
        for (const residenceKey in unit.synaps.residenceUnits) {
            residence = unit.synaps.residenceUnits[residenceKey];
        }
    }

    if (residence) {

        if (!unit.params) {
            unit.params = {}
        }
        if (residence.params) {
            Object.assign(unit.params, residence.params);
        }
        if (!unit.equipment) {
            unit.equipment = {}
        }
        if (residence.equipment) {
            Object.assign(unit.equipment, residence.equipment);
        }
        if (!unit.appliances) {
            unit.appliances = {}
        }
        if (residence.appliances) {
            Object.assign(unit.appliances, residence.appliances);
        }
        if (!unit.security) {
            unit.security = {}
        }
        if (residence.security) {
            Object.assign(unit.security, residence.security);
        }
        if (!unit.area) {
            unit.area = {}
        }
        if (residence.area) {
            Object.assign(unit.area, residence.area);
        }
        if (!unit.address) {
            unit.address = {}
        }
        if (residence.address) {
            _getIfNull(unit.address, residence.address, 'city');
            _getIfNull(unit.address, residence.address, 'country');
            _getIfNull(unit.address, residence.address, 'district');
            _getIfNull(unit.address, residence.address, 'eCity');
            _getIfNull(unit.address, residence.address, 'eCityName');
            _getIfNull(unit.address, residence.address, 'eGps');
            _getIfNull(unit.address, residence.address, 'gps');
            _getIfNull(unit.address, residence.address, 'sector');
            _getIfNull(unit.address, residence.address, 'postcode');
            _getIfNull(unit.address, residence.address, 'street');
            _getIfNull(unit.address, residence.address, 'streetNb');
            _getIfNull(unit.address, residence.address, 'note');
            // Check if unit sectors is empty
            if (!(unit.address.sectors && unit.address.sectors.length) && residence.address.sectors) {
                unit.address.sectors = residence.address.sectors;
            }
        }
        if (residence.landType && !unit.landType) {
            unit.landType = residence.landType;
        }
        if (residence.leasehold && !unit.leasehold) {
            unit.leasehold = residence.leasehold;
        }
        if (residence.builtYear && !unit.builtYear) {
            unit.builtYear = residence.builtYear;
        }
        if (residence.topFloor && !unit.topFloor) {
            unit.topFloor = residence.topFloor;
        }
    }
    return residence;
}

export function refreshPhotos(photosObj: any): any[] {
    if (!photosObj) {
        return [];
    }

    const photos: any[] = [];
    for (const fileKey in photosObj) {
        photos.push(Object.assign({}, photosObj[fileKey], { $key: fileKey }));
    }
    photos.sort((a, b) => a.order - b.order);

    return photos;
}

function _getIfNull(unit: any, residence: any, field: string): void {
    if (!unit[field] && residence[field]) {
        unit[field] = residence[field];
    }
}

export function accessible_to_foreigners(
    mandateType: string,
    residence: boolean,
    scheme: string | null,
    leasehold: boolean,
    unitType: string,
    topFloor: number,
    priceMUR: number,
    priceUSD: number,
    asResidencePermit: boolean,
    totalUnits: number | null,
    // landSurfaceM2: number,
): boolean {
    if (mandateType === 'sale' &&
        (scheme // RES, IRS, PDS, Smart City
            || (!leasehold && // Not on Residential Lease
                (
                    (unitType === 'apartment' && residence && topFloor && topFloor >= 2 && priceMUR && priceMUR >= 6000000 && (!totalUnits || totalUnits > 4)) || // G+2 law
                    (asResidencePermit && priceUSD > 500000) //  New law - TODO: add land restriction
                )
            )
        )
    ) {
        return true;
    }
    return false;
}

export function resident_permit(
    mandateType: string,
    residence: boolean,
    scheme: string | null,
    leasehold: boolean,
    unitType: string,
    topFloor: number,
    priceUSD: number,
): boolean {
    if (
        mandateType === 'sale' && residence &&
        (
            scheme // RES, IRS, PDS, Smart City
            || (!leasehold && (unitType === 'apartment' && topFloor && topFloor >= 2)) // G+2 law
        )
        && priceUSD && priceUSD >= 375000
    ) {
        return true;
    }
    return false;
}

export function check_G2(unit: any, mandate: any): boolean {
    if (unit && unit.topFloor && !unit.leasehold) {
        const topFloor: number = parseInt(unit.topFloor.substring(1));
        if (topFloor >= 2) {
            if (unit.type === 'apartment') {
                if (mandate.priceCies && mandate.priceCies.mur >= 6000000) {
                    return true;
                }
            }
        }
    }
    return false;
}

export function land_m2(surface: number, unit: string): number {
    if (surface && unit) {
        if (unit === 'm2') {
            return Math.round(surface);
        }
        if (unit === 'arp') {
            return Math.round(surface * 4221);
        }
        if (unit === 'rd') {
            return Math.round(surface * 42.21);
        }
        if (unit === 'arp') {
            return Math.round(surface * 4221);
        }
    }
    return 0;
}


export function formatNumberWithCommas(x: number): string {
    return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0';
}

export function formatCy(cy: string): string {
    if (cy === 'mur' || cy === 'MUR') {
        return 'Rs';
    }
    if (cy === 'usd' || cy === 'USD') {
        return '$';
    }
    if (cy === 'eur' || cy === 'EUR') {
        return '€';
    }
    if (cy === 'zar' || cy === 'ZAR') {
        return 'Rd';
    }
    return '';
}

export function formatDate(date: Date): string {
    if (date) {
        return `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(date.getDate())}`;
    }
    return '';
}

export function addZero(nb: number): string {
    if (nb > 9) {
        return nb + '';
    } else {
        return '0' + nb;
    }
}

export function floorToInt(floor: string): number | null {

    return (floor && floor.length > 1) ? Number(floor.substring(1)) : null;

}

export function slugLangUnit(unit: any, residence: any, lang: string): string {
    let slug = '';

    const preSettings: any = new PreSettings();

    if (unit.address && unit.address.eCityName) {
        slug += unit.address.eCityName;
    }
    if (residence && residence.name) {
        slug += ' ' + residence.name;
    }
    if (unit.type) {
        const unitTypes: any = preSettings.getParamValues('unitTypes');
        if (unitTypes[unit.type] && unitTypes[unit.type].name) {
            slug += ' ' + unitTypes[unit.type].name[lang];
        }
    }
    if (residence && residence.scheme) {
        const residenceSchemes: any = preSettings.getParamValues('residenceSchemes');
        if (residenceSchemes[residence.scheme] && residenceSchemes[residence.scheme].name) {
            slug += ' ' + residenceSchemes[residence.scheme].name[lang];
        }
    }
    slug += ' ' + unit.ref;

    return slug;
}

export function slugLangUnitRes(unit: any, lang: string): string {
    const residence = mergeResidenceUnitSynap(unit);
    return slugLangUnit(unit, residence, lang);
}


export function getWebURLs(mandate: any) {

    let unit: any = null;
    if (mandate?.synaps?.unitMandates) {
        for (const unitKey in mandate.synaps.unitMandates) {
            unit = mandate.synaps.unitMandates[unitKey];
        }
    }
    const sale: { [lang: string]: string } = {
        fr: "achat-ile-maurice",
        en: "buy-mauritius",
        de: "kaufen-mauritius",
        ru: "купить-маврикий",
    }

    const rent: { [lang: string]: string } = {
        fr: "location-ile-maurice",
        en: "rent-mauritius",
        de: "mieten-mauritius",
        ru: "аренда-маврикий",
    }

    if (unit) {
        return {
            fr: `https://www.nestenn.mu/fr/${mandate.type === 'sale' ? sale['fr'] : rent['fr']}/${slugify(slugLangUnitRes(unit, 'fr'))}`,
            en: `https://www.nestenn.mu/en/${mandate.type === 'sale' ? sale['en'] : rent['en']}/${slugify(slugLangUnitRes(unit, 'en'))}`,
            de: `https://www.nestenn.mu/de/${mandate.type === 'sale' ? sale['de'] : rent['de']}/${slugify(slugLangUnitRes(unit, 'de'))}`,
            ru: `https://www.nestenn.mu/ru/${mandate.type === 'sale' ? sale['ru'] : rent['ru']}/${slugify(slugLangUnitRes(unit, 'ru'))}`,
        }
    }
    return {
        fr: `https://www.nestenn.mu/fr`,
        en: `https://www.nestenn.mu/en`,
        de: `https://www.nestenn.mu/de`,
        ru: `https://www.nestenn.mu/ru`,
    }
}

export function slugLangRes(residence: any, lang: string): string {
    let slug = '';

    const preSettings: any = new PreSettings();

    if (residence.address && residence.address.eCityName) {
        slug += residence.address.eCityName;
    }
    if (residence && residence.name) {
        slug += ' ' + residence.name;
    }
    if (residence && residence.scheme) {
        const residenceSchemes: any = preSettings.getParamValues('residenceSchemes');
        if (residenceSchemes[residence.scheme] && residenceSchemes[residence.scheme].name) {
            slug += ' ' + residenceSchemes[residence.scheme].name[lang];
        }
    }
    slug += ' ' + residence.ref;

    return slug;
}


export function slugify(string: string): string {

    const a = 'àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœṕŕßśșțùúüûǘẃẍÿź·/_,:;'
    const b = 'aaaaaaaaceeeeghiiiimnnnoooooprssstuuuuuwxyz------'
    const p = new RegExp(a.split('').join('|'), 'g')

    return string.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with ‘and’
        .replace(/[^\w\-]+/g, '-') // Remove all non-word characters
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '') // Trim - from end of text
}

export function checkStatusActive(data: any): void {
    const actions: { [actionType: string]: number } = {}
    // const status = ['active', 'offer', 'signAgree', 'signDeed', 'signLease', 'payment']
    if (data && data.status && data.status === 'active' && data.synaps) {
        if (data.synaps.projectsActions) {
            for (const actionKey in data.synaps.projectsActions) {
                const action = data.synaps.projectsActions[actionKey];
                if (action.type) {
                    if (!actions[action.type]) {
                        actions[action.type] = 0;
                    }
                    if (!action.done) {
                        actions[action.type]++;
                    }
                }
            }
        }
        if (data.synaps.mandatesActions) {
            for (const actionKey in data.synaps.mandatesActions) {
                const action = data.synaps.mandatesActions[actionKey];
                if (action.type) {
                    if (!actions[action.type]) {
                        actions[action.type] = 0;
                    }
                    if (!action.done) {
                        actions[action.type]++;
                    }
                }
            }
        }

        if (actions.payment) {
            data.status = 'payment';
        }
        else if (actions.signDeed) {
            data.status = 'signDeed';
        }
        else if (actions.signLease) {
            data.status = 'signLease';
        }
        else if (actions.signAgree) {
            data.status = 'signAgree';
        }
        else if (actions.offer) {
            data.status = 'offer';
        }
    }
}

export function customStringify(obj: any) {
    const seen = new WeakSet();
    return JSON.stringify(obj, (key, value) => {
        if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
                return '[Circular Reference]';
            }
            seen.add(value);
        }
        return value;
    });
}

export function mergeDeep(target: any, ...sources: any): any {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (
                isObject(source[key]) // Check if Object
                && !source[key].Vc // Except GPS
                && typeof source[key].getMonth !== 'function' // Except Date
                && typeof source[key].toDate !== 'function' // Except TimeStamp
            ) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeDeep(target, ...sources);
}

export function isObject(item: any) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

export interface ActionAgencyFee {
    type?: string,
    comPerc?: number,
    comFix?: number,
    comMin?: number,
}

export function calculAgencyComSaleExclVat(data: any, actionAgencyFee: ActionAgencyFee, mandateType: string | null, xrate: number = 0): number {

    if (!(actionAgencyFee && data && data.price && data.cy && mandateType)) {
        return 0;
    }

    const amount = data.price;

    if (actionAgencyFee.type === 'fix') {
        return actionAgencyFee.comFix ? actionAgencyFee.comFix : 0;
    }

    if (actionAgencyFee.type === 'no') {
        return 0;
    }

    let total = amount;

    if (mandateType === 'sale') {
        if (actionAgencyFee.type === 'otherPerc' && actionAgencyFee.comPerc) {
            total = total * actionAgencyFee.comPerc / 100;
        } else {
            total = total * 2 / 100;
        }
    } else if (actionAgencyFee.type === 'otherPerc' && actionAgencyFee.comPerc) {
        total = total * actionAgencyFee.comPerc / 100;
    }
    if (actionAgencyFee.type === 'discount50') {
        total = total / 2;
    }

    if (actionAgencyFee.type === 'discountVAT') {
        total = total / 1.15;
    }

    if (actionAgencyFee.comMin && total < actionAgencyFee.comMin) {
        total = actionAgencyFee.comMin;
    }

    if (xrate) {
        total = total * xrate;
    }

    return Math.round(total);
}



export function calculAgencyComSaleInclVat(amount: number, actionAgencyFee: ActionAgencyFee, mandateType: string, xrate: number = 0): number {
    return Math.round(calculAgencyComSaleExclVat(amount, actionAgencyFee, mandateType, xrate) * 1.15);
}

export function addVat(amount: number): number {
    return Math.round(amount * 1.15);
}

export function dateDay(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}


export function dateMonth(date: Date): number {
    if (date) {
        return date.getMonth() + 1;
    }
    return 0;
}

export function dateYear(date: Date): number {
    if (date) {
        return date.getFullYear();
    }
    return 0;
}

