<mat-toolbar>
    <div>
        <a mat-icon-button (click)="clickBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </a>
        <span>{{db.itemsFiltered.length}} Marketing Checks</span>
    </div>
    <profile></profile>
</mat-toolbar>
<div class="toolbartop"></div>


<div class="main-container-flex split" *ngIf="db.auth.profile?.values">
    <mat-spinner *ngIf="!db.loaded" [strokeWidth]="5"></mat-spinner>
    <app-table class="main" [offset]="db.itemsPageConfig.scrollIndex" tableKey="mainRealtor" [item]="actionItem"
        [items]="db.itemsFilteredOrdered" (clickFn)="goItem($event)" (scrollFn)="scrollItems($event)"
        (overFn)="overMatching($event)"></app-table>
    <div class="side">
        <app-unit-card [values]="preview" [mandate]="true"></app-unit-card>
    </div>
</div>