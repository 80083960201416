import { AgmMap } from '@agm/core';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { addZero } from 'functions/src/shared/tools';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/common/services/auth/auth.service';
import { SettingService } from 'src/common/services/setting/setting.service';

@Injectable({
  providedIn: 'root'
})
export class StatService {

  private _itemsSubcription: any;
  public statList$: Observable<any[]> = null
  public statList: any[] = [];
  public statChart: any[] = [];
  public statChartPie: any[];
  public loaded = false;

  // public statCols = {
  //   marketing: []
  // }


  public statConfs = {
    actions: {
      name: 'Actions Report',
      mandateType: false,
      fields: 'actions',
      legend: this.setting.getParamsObj('actionTypes'),
    },
    projectOrigins: {
      name: 'Project Origins',
      mandateType: true,
      fields: 'projectOrigins',
      legend: this.setting.getParamsObj('projectOrigins'),
    },
    mandateOrigins: {
      name: 'Mandate Origins',
      mandateType: true,
      fields: 'mandateOrigins',
      legend: this.setting.getParamsObj('mandateOrigins'),
    },
    newMandates: {
      name: 'New Mandates',
      mandateType: true,
      fields: 'newMandates',
      legend: this.setting.getParamsObj('newMandates'),
    },
    newMandatesVEFA: {
      name: 'New Mandates including Off-Plan',
      mandateType: true,
      fields: 'newMandatesVEFA',
      legend: this.setting.getParamsObj('newMandatesVEFA'),
    },
    sales: {
      name: 'Committed Turnover - Sales/Rentals',
      mandateType: false,
      fields: 'sales',
      legend: this.setting.getParamsObj('mandateTypes'),
    },
    salesType: {
      name: 'Committed Turnover - Projects/Mandates',
      mandateType: true,
      fields: 'salesType',
      legend: this.setting.getParamsObj('sales'),
    },
    nbDeals: {
      name: 'Nb of Deals',
      mandateType: false,
      fields: 'nbDeals',
      legend: this.setting.getParamsObj('mandateTypes'),
      list: {
        module: 'actions',
        filters: { server: { done: true, type: 'signLease' } },
      }
    },
    nbDealProjects: {
      name: 'Nb of Deal Projects',
      mandateType: false,
      fields: 'nbDealProjects',
      legend: this.setting.getParamsObj('mandateTypes'),
    },
    nbDealMandates: {
      name: 'Nb of Deal Mandates',
      mandateType: false,
      fields: 'nbDealMandates',
      legend: this.setting.getParamsObj('mandateTypes'),
    },
    projects: {
      name: 'Stock Projects',
      mandateType: true,
      fields: 'projects',
      legend: this.setting.getParamsObj('temperatures'),
    },
    mandates: {
      name: 'Stock Mandates',
      mandateType: true,
      fields: 'mandates',
      legend: this.setting.getParamsObj('temperatures'),
    },
    mandatesVEFA: {
      name: 'Stock Mandates incl. Off-Plan',
      mandateType: true,
      fields: 'mandates',
      legend: this.setting.getParamsObj('temperaturesVEFA'),
    },
    byAgency: {
      name: 'By Agency',
      mandateType: false,
      fields: 'byAgency',
      legend: this.setting.getParamsObj('agencies'),
    },
    byRealtor: {
      name: 'By Realtor',
      mandateType: false,
      fields: 'byRealtor',
      legend: this.setting.getParamsObj('users'),
    },
  }

  constructor(
    protected afs: AngularFirestore,
    protected setting: SettingService,
    public auth: AuthService,
  ) {
  }

  initList(statReport: string, agency: string, realtor: string, year: number, month: number, mandateType: string, uniqueStat: string = null, uniqueStatPerc: boolean = false) {

    // this.stats$ = this._colWithIds(`/stats`, (ref) => this._queryFn(ref, module, $key));

    let dbpath = `/stats/${realtor ? 'realtors' : (agency ? 'agencies' : 'all')}/${(year && month) ? 'days' : (year ? 'months' : 'years')}/`;

    this.statList$ = this._colWithIds(dbpath, (ref) => this._queryFn(ref, year, month, agency, realtor));

    this.loaded = false;

    if (this._itemsSubcription) {
      this._itemsSubcription.unsubscribe();
    }

    this._itemsSubcription = this.statList$.subscribe(items => {
      this.statList = items;
      this.statChart = this._toChart(statReport, mandateType, year, month, uniqueStat, uniqueStatPerc, agency === 'byAgency' ? 'agency' : (realtor === 'byRealtor' ? 'realtor' : null));
      this.loaded = true;
    });
  }

  private _queryFn(ref, year: number, month: number, agency: string, realtor: string): any {

    let query = ref;

    if (year) {
      query = query.where('year', '==', year);
      if (month) {
        query = query.where('month', '==', month);
      }
    }
    if (realtor && realtor !== 'byRealtor') {
      query = query.where('realtor', '==', realtor);
    }
    if (agency && agency !== 'byAgency') {
      query = query.where('agency', '==', agency);
    }

    query = query.orderBy('date', 'asc');

    return query;
  }

  public refreshChartOptions(statReport: string, mandateType: string, year: number, month: number, uniqueStat: string, uniqueStatPerc: boolean = false, by: string = null) {
    this.statChart = this._toChart(statReport, mandateType, year, month, uniqueStat, uniqueStatPerc, by);
  }

  private _toChart(statReport: string, mandateType: string, year: number, month: number, uniqueStat: string = null, uniqueStatPerc: boolean, by: string | null): any[] {
    const tmp: any[] = [];
    this.statChartPie = [];

    let statConf = JSON.parse(JSON.stringify(this.statConfs[statReport]));

    const daysInMonth = new Date(year, month, 0).getDate();
    const dateMap = new Map<string, any>();

    const pieChartMap = new Map<string, number>();


    let statList = [];


    if (by === 'agency') {
      let statObj: any = {};
      let date = null;
      for (const item of this.statList) {
        if (!date) {
          date = item.date;
        }
        if (date.seconds !== item.date.seconds) {
          statList.push({ date: date, byAgency: statObj });
          date = item.date;
          statObj = {};
        }
        statObj[item.agency] = item[statConf.fields]?.all ? item[statConf.fields]?.all : 0;
      }
      statList.push({ date: date, byAgency: statObj });

      statConf = JSON.parse(JSON.stringify(this.statConfs.byAgency));
    } else if (by === 'realtor') {
      let statObj: any = {};
      let date = null;
      for (const item of this.statList) {
        if (!date) {
          date = item.date;
        }
        if (date.seconds !== item.date.seconds) {
          statList.push({ date: date, byRealtor: statObj });
          date = item.date;
          statObj = {};
        }
        statObj[item.realtor] = item[statConf.fields]?.all ? item[statConf.fields]?.all : 0;

      }
      statList.push({ date: date, byRealtor: statObj });

      // this.statConfs.byRealtor.legend = this.setting.realtorsAgencyObj[agency];
      statConf = JSON.parse(JSON.stringify(this.statConfs.byRealtor));
    } else {
      statList = this.statList;
    }

    //Refresh Legend
    this.refreshLegend(statConf, statList);

    // Loop all stats 
    for (const item of statList) {
      if (item.date?.seconds) {
        const jsDate = item.date.toDate();
        const dateKey = '' + (month ? addZero(jsDate.getDate()) : (year ? addZero(jsDate.getMonth() + 1) : jsDate.getFullYear()));
        const obj = {
          name: dateKey,
          series: []
        };
        let allValue = 0;


        if (uniqueStat) {
          obj.series.push({
            name: 'All',
            value: 0,
          });
          allValue = mandateType ? ((item[statConf.fields] && item[statConf.fields][mandateType] && item[statConf.fields][mandateType]['all']) ? item[statConf.fields][mandateType]['all'] : 0) : ((item[statConf.fields] && item[statConf.fields]['all']) ? item[statConf.fields]['all'] : 0);
        }
        for (const field in statConf.legend) {
          if (field !== 'all') {
            if (!uniqueStat || (uniqueStat && statConf.legend[field].name === uniqueStat)) {

              let value = mandateType ? ((item[statConf.fields] && item[statConf.fields][mandateType] && item[statConf.fields][mandateType][field]) ? item[statConf.fields][mandateType][field] : 0) : ((item[statConf.fields] && item[statConf.fields][field]) ? item[statConf.fields][field] : 0)

              if (uniqueStatPerc) {
                value = allValue ? Math.round(value / allValue * 100) : 0;
              }

              obj.series.push({
                name: statConf.legend[field].name,
                value: value,
              });

              // Update pie chart map
              if (pieChartMap.has(statConf.legend[field].name)) {
                pieChartMap.set(statConf.legend[field].name, pieChartMap.get(statConf.legend[field].name) + value);
              } else {
                pieChartMap.set(statConf.legend[field].name, value);
              }
            }
            if (uniqueStat && statConf.legend[field].name !== uniqueStat) {
              obj.series.push({
                name: statConf.legend[field].name,
                value: 0,
              });
            }

          }
        }


        dateMap.set(dateKey, obj);
      }
    }

    // Convert pie chart map to array
    this.statChartPie = Array.from(pieChartMap, ([name, value]) => ({ name, value }));





    // Fill MISSING DATES

    if (year && month) {// SPECIFIC MONTH
      for (let day = 1; day <= daysInMonth; day++) {
        const dateKey = addZero(day);
        if (!dateMap.has(dateKey)) {
          const obj = {
            name: dateKey,
            series: []
          };

          for (const field in statConf.legend) {
            if (field !== 'all') {
              obj.series.push({
                name: statConf.legend[field].name,
                value: 0,
              });
            }
          }

          tmp.push(obj);
        } else {
          tmp.push(dateMap.get(dateKey));
        }
      }
    } else if (year) { // SPECIFIC YEAR
      for (let month = 1; month <= 12; month++) {
        const dateKey = addZero(month);
        if (!dateMap.has(dateKey)) {
          const obj = {
            name: dateKey,
            series: []
          };

          for (const field in statConf.legend) {
            if (field !== 'all') {
              obj.series.push({
                name: statConf.legend[field].name,
                value: 0,
              });
            }
          }

          tmp.push(obj);
        } else {
          tmp.push(dateMap.get(dateKey));
        }
      }
    } else { // ALL YEARS
      const currentYear = new Date().getFullYear();
      for (let year = 2018; year <= currentYear; year++) {
        const dateKey = '' + year;
        if (!dateMap.has(dateKey)) {
          const obj = {
            name: dateKey,
            series: []
          };

          for (const field in statConf.legend) {
            if (field !== 'all') {
              obj.series.push({
                name: statConf.legend[field].name,
                value: 0,
              });
            }
          }
          tmp.push(obj);
        } else {
          tmp.push(dateMap.get(dateKey));
        }
      }
    }

    return tmp;
  }


  // initStatCols() {
  //   this.statCols.marketing = this.setting.getParamsSelect('projectOrigins');
  // }


  public refreshLegend(statConf, statList) {
    // Remove statConf Legends not in statList
    for (const field in statConf.legend) {
      let found = false;
      for (const item of statList) {
        if (item[statConf.fields] && item[statConf.fields][field]) {
          found = true;
          break;
        }
      }
      if (!found) {
        delete statConf.legend[field];
      }
    }
  }

  protected _colWithIds(path, queryFn?): Observable<any[]> {
    return this.afs.collection(path, queryFn).snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            return a.payload.doc.data();
            // return new this.itemClass(a.payload.doc.data());
          });
        })
      );
  }
}



@Injectable({
  providedIn: 'root'
})
export class MystatService extends StatService {

  constructor(
    protected afs: AngularFirestore,
    protected setting: SettingService,
    public auth: AuthService,
  ) {
    super(afs, setting, auth);
  }

}
